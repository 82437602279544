import { derived, RootState } from 'overmind';
import { VerifyError, GenericError } from "../_types";

export type State = {
    current: string | number;
    token: string | null;
    tokenDescriptor: string | null;
    tokenErrors: VerifyError[]; 
    submitErrors: GenericError[]; 
    termsAccepted: boolean;
    autoPayAuthorized: boolean;
    signUpSuccess: boolean;
    autoPayEnabled: boolean;
    autoPayConfigured: boolean;
    billing: {
        ccFirstName: string | null;
        ccLastName: string | null;
        ccEmail: string | null;
        ccPhone: string | null;
        ccAddress: string | null;
        ccCity: string | null;
        ccState: string | null;
        ccZip: string | null;
        ccCode: string | null;
    }
}

export const state: State = {
    token: null,
    tokenDescriptor: null,
    tokenErrors: [],
    submitErrors: [],
    termsAccepted: false,
    autoPayAuthorized: false,
    signUpSuccess: false,
    billing: {
        ccFirstName: null,
        ccLastName: null,
        ccEmail: null,
        ccPhone: null,
        ccAddress: null,
        ccCity: null,
        ccState: null,
        ccZip: null,
        ccCode: null
    },
    autoPayEnabled: derived( (state: State, root: RootState) => root.profile.data && root.profile.data.autoPayEnabled ? true : false ),
    autoPayConfigured: derived( (state: State, root: RootState) => root.profile.data && root.profile.data.autoPayConfigured ? true : false ),
    current: derived( (state: State, root: RootState) => root.autopay.states[0][1] ),
}