import { Action, AsyncAction } from 'overmind';
import { InvoiceToPay, PaymentFormDataCC, PaymentFormDataACH, PaymentPayload } from 'state/payments/_types';
import { formatDate } from 'utils/dates';
import { extractNameParts } from 'utils/strings';
import { State } from './state';

export const selectPaymentType: AsyncAction<State['paymentMode']> = async ({ state }, paymentMode) => {
    window.heap.track('PaymentMode_ACH_Selected');
    state.payments.paymentMode = paymentMode;
    return Promise.resolve();
}

export const selectInvoices: AsyncAction = async ({ state }) => {
    let selectedInvoices = Object.values(state.profile.invoiceItems).filter((invoice)=>{
        return invoice.selected;
    })
    let selectedInvoicesIds = selectedInvoices.reduce((invoicesToPay, invoice)=>{
        return invoicesToPay = [...invoicesToPay, {
            id: invoice.invoice_id,
            alt_id: invoice.full_id,
            date: formatDate(invoice.invoice_date),
            amount: invoice.balance,
        }];
    }, [] as InvoiceToPay[])
    state.payments.invoices = selectedInvoicesIds;
    return Promise.resolve();
}

export const getPaymentTokenCC: AsyncAction<PaymentFormDataCC> = async ({ state, effects }, payload) => {
    window.heap.track('PaymentToken_Requested');
    try {

        // Replace WhiteSpace In Card Number
        payload.ccNumber = payload.ccNumber.replace(/\s+/g,'');

        let { dataDescriptor, dataValue } = await effects.payments.fetchPaymentTokenCC(payload);

        if(dataDescriptor&&dataValue) {
            state.payments.token = dataValue;
            state.payments.tokenDescriptor = dataDescriptor;
        }

        let billingName = extractNameParts(payload.ccName);

        state.payments.billing.ccFirstName = billingName.firstName;
        state.payments.billing.ccLastName = billingName.lastName;
        state.payments.billing.ccAddress = payload.ccAddress;
        state.payments.billing.ccCity = payload.ccCity;
        state.payments.billing.ccState = payload.ccState;
        state.payments.billing.ccZip = payload.ccZip;

        window.heap.track('PaymentToken_Success');
        return Promise.resolve();

    } catch (errors) {
        window.heap.track('PaymentToken_Failure');
        state.payments.tokenErrors = errors;
        return Promise.reject();
    }
}

export const getPaymentTokenACH: AsyncAction<PaymentFormDataACH> = async ({ state, effects }, payload) => {
    window.heap.track('PaymentToken_Requested');
    try {

        // Remove Special Characters
        payload.achAccountName = payload.achAccountName.replace(/[^a-zA-Z0-9 ]/g, '');

        // Auth.NET Only Allows Up To 22 Characters Without Throwing Error
        if ( payload.achAccountName.length > 15 ) {
            payload.achAccountName = payload.achAccountName.substring(0, 15);
        }

        let { dataDescriptor, dataValue } = await effects.payments.fetchPaymentTokenACH(payload);

        if(dataDescriptor&&dataValue) {
            state.payments.token = dataValue;
            state.payments.tokenDescriptor = dataDescriptor;
        }

        let billingName = extractNameParts(payload.achName);

        state.payments.billing.achFirstName = billingName.firstName;
        state.payments.billing.achLastName = billingName.lastName;
        state.payments.billing.achAddress = payload.achAddress;
        state.payments.billing.achCity = payload.achCity;
        state.payments.billing.achState = payload.achState;
        state.payments.billing.achZip = payload.achZip;

        window.heap.track('PaymentToken_Success');
        return Promise.resolve();

    } catch (errors) {
        window.heap.track('PaymentToken_Failure');
        state.payments.tokenErrors = errors;
        return Promise.reject();
    }
}

export const acceptTerms: Action<boolean> = ({ state }, status) => {
    state.payments.termsAccepted = status;
}

export const authorizeCharge: Action<boolean> = ({ state }, status) => {
    state.payments.chargeAuthorized = status;
}

export const submitTransactionCC: AsyncAction = async ({ state, effects }) => {

    window.heap.track('BillPay_Transaction_Submitted');

    let { amount, invoices, token, tokenDescriptor, paymentMode, billing: { ccFirstName, ccLastName, ccAddress, ccCity, ccState, ccZip } } = state.payments;

    if ( 
        token && 
        tokenDescriptor && 
        invoices.length && 
        state.profile.id &&
        ccFirstName &&
        ccLastName &&
        ccAddress &&
        ccCity &&
        ccState &&
        ccZip
        ) {

        let payload: PaymentPayload = {
            profile: state.profile.id,
            amount,
            invoices,
            token,
            tokenDescriptor,
            type: paymentMode,
            billing: {
                ccFirstName,
                ccLastName,
                ccAddress,
                ccCity,
                ccState,
                ccZip,
            }
        }

        try {            
            let { transId } = await effects.payments.submitPayment(payload);
            if (transId) {
                window.heap.track('BillPay_Transaction_Success');
                state.payments.chargeSuccess = true;
                state.payments.verificationCode = transId;
            }
            return Promise.resolve();
        } catch (errors) {
            window.heap.track('BillPay_Transaction_Failure');
            state.payments.chargeErrors = errors;
            return Promise.reject();
        }

    } else {
        window.heap.track('BillPay_Transaction_Failure');
        state.payments.chargeErrors = [{code:'101', text:'Missing Data'}]
        return Promise.reject()
    } 
 
}

export const submitTransactionACH: AsyncAction = async ({ state, effects }) => {

    window.heap.track('BillPay_Transaction_Submitted');

    let { amount, invoices, token, tokenDescriptor, paymentMode, billing: { achFirstName, achLastName, achAddress, achCity, achState, achZip } } = state.payments;

    if ( 
        token && 
        tokenDescriptor && 
        invoices.length && 
        state.profile.id &&
        achFirstName &&
        achLastName &&
        achAddress &&
        achCity &&
        achState &&
        achZip
        ) {

        let payload: PaymentPayload = {
            profile: state.profile.id,
            amount,
            invoices,
            token,
            tokenDescriptor,
            type: paymentMode,
            billing: {
                achFirstName: achFirstName,
                achLastName: achLastName,
                achAddress: achAddress,
                achCity: achCity,
                achState: achState,
                achZip: achZip,
            }
        }

        try {            
            let { transId } = await effects.payments.submitPayment(payload);
            if (transId) {
                window.heap.track('BillPay_Transaction_Success');
                state.payments.chargeSuccess = true;
                state.payments.verificationCode = transId;
            }
            return Promise.resolve();
        } catch (errors) {
            window.heap.track('BillPay_Transaction_Failure');
            state.payments.chargeErrors = errors;
            return Promise.reject();
        }

    } else {
        window.heap.track('BillPay_Transaction_Failure');
        state.payments.chargeErrors = [{code:'101', text:'Missing Data'}]
        return Promise.reject()
    } 
 
}

export const next: Action<any> = ({ state }, callback) => {
    if ( typeof callback === 'function' ) {
        callback();
    }
}

export const back: Action<any> = ({ state }, callback) => {
    if ( typeof callback === 'function' ) {
        callback();
    }
}

export const resetErrors: Action = ({state}) => {
    state.payments.tokenErrors = [];
    state.payments.chargeErrors = [];
}

export const resetState: AsyncAction = async ({ state, actions }) => {
    state.code = null;
    state.payments.token = null;
    state.payments.tokenDescriptor = null;
    state.payments.chargeSuccess = false;
    state.payments.verificationCode = null;
    state.payments.termsAccepted = false;
    state.payments.chargeAuthorized = false;
    state.payments.tokenErrors = [];
    state.payments.chargeErrors = [];
    state.payments.invoices = [];
    state.payments.billing.ccFirstName = null;
    state.payments.billing.ccLastName = null;
    state.payments.billing.ccAddress = null;
    state.payments.billing.ccCity = null;
    state.payments.billing.ccState = null;
    state.payments.billing.ccZip = null;
    if(state.profile.id){
        await actions.profile.loadProfile(state.profile.id);
    }
}

export const viewedForm: Action<any> = ({state}, data) => {
    window.heap.track('BillPay_ViewedFormPage');
}

export const viewedReview: Action<any> = ({state}, data) => {
    window.heap.track('BillPay_ViewedReviewPage');
}

export const viewedSummary: Action<any> = ({state}, data) => {
    window.heap.track('BillPay_ViewedSummaryPage');
}