import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import cardValidator from 'card-validator';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { Grid, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Select, Button, InputRightElement, InputGroup, Box, Text, Flex, Stack, Alert } from '@chakra-ui/core';

import { getActions, getState } from 'state';

import { VerifyError } from 'state/_types';
import { PageProps } from 'screens/pages/_types';
import { AutoPayFormDataCC } from 'state/autopay/_types';

import { AnimatedHeading } from 'components/ui/Animated';

import { FORM_PHONE_VALIDATION_REGEX, IsValid } from 'utils/forms';
import { StatusIcon } from 'components/forms';
import ActionBar from 'components/ui/ActionBar';

const StagingInitialFormData = {
    ccName: 'Bobba Fett',
    ccEmail: 'bobba@mercs.com',
    ccPhone: '7869110690',
    ccNumber: '4111111111111111',
    ccMonth: '01',
    ccYear: '21',
    ccCode: '900',
    ccAddress: '101 Imperial Way',
    ccCity: 'Miami',
    ccState: 'Fl',
    ccZip: '46201'
}

const ProductionInitialFormData = {
    ccName: '',
    ccEmail: '',
    ccPhone: '',
    ccNumber: '',
    ccMonth: '',
    ccYear: '',
    ccCode: '',
    ccAddress: '',
    ccCity: '',
    ccState: '',
    ccZip: ''
}

const InitialFormData = process.env.REACT_APP_APP_ENV === 'production' ? ProductionInitialFormData : StagingInitialFormData;

const ValidationSchema = yup.object().shape({
    ccName: yup.string()
        .test('ccName','Name On Card Is Required', (value) => cardValidator.cardholderName(value).isValid )
        .required('Name On Card Is Required'),
    ccEmail: yup.string()
        .required('Email Is Required')
        .email('Email Is Invalid'),
    ccPhone: yup.string()
        .matches(FORM_PHONE_VALIDATION_REGEX, 'Phone Is Invalid')
        .required('Phone Is Required'),
    ccNumber: yup.string()
        .test('ccNumber','Credit Card Number Is Invalid', (value) => cardValidator.number(value,{maxLength: 16}).isValid )
        .required('Credit Card Number Is Required'),
    ccMonth: yup.string()
        .test('ccMonth','Expiration Month Is Invalid', (value) => cardValidator.expirationMonth(value).isValid )
        .required('Expiration Month Is Required'),
    ccYear: yup.string()
        .test('ccYear','Expiration Year Is Invalid', (value) => cardValidator.expirationYear(value).isValid )
        .required('Expiration Year Is Required'),
    ccCode: yup.string()
        .test('ccCode','CVV Is Invalid', (value) => cardValidator.cvv(value,[3,3]).isValid )
        .required('CVV Is Required'),
    ccAddress: yup.string()
        .required('Billing Address Is Required'),
    ccCity: yup.string()
        .required('Billing City Is Required'),
    ccState: yup.string()
        .required('Billing State Is Required'),
    ccZip: yup.string()
        .required('Zip Code Is Required'),
});

const AutoPayFormCC: React.FC<PageProps> = ({handleNext, handleBack}) => {

    const { getPaymentTokenCC } = getActions().autopay;
    const { tokenErrors } = getState().autopay;

    const handleSubmit = async (values: AutoPayFormDataCC, { setSubmitting }: FormikHelpers<AutoPayFormDataCC>) => {
        try {
            await getPaymentTokenCC(values);
            setSubmitting(false);
            if(handleNext) {
                handleNext();
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    const displayErrors = () => {
        if (!tokenErrors.length) return null;
        return (
            <Stack spacing={3}>
                {tokenErrors.map((error: VerifyError, key)=>{
                    return (
                        <Alert status="error" variant="left-accent" key={key}>
                            <strong>{error.code}:</strong>{' '}<span>{error.text}</span>
                        </Alert>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Formik 
            initialValues={InitialFormData}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => { 
            
                return(

                    <form id="requestAutoPayToken" onSubmit={handleSubmit}>

                        <Box layerStyle="appBody" mb={5}>

                            <Box>
                                <AnimatedHeading title="Payment Details" size="md">
                                    <Text color="gray.500" fontSize="sm" align="center">Please provide your payment details below to use for AutoPay.</Text>
                                </AnimatedHeading>
                            </Box>
                            <hr/>

                            <Grid templateRows="1fr" gap={5}>

                                <Grid templateColumns="repeat(2, 1fr)" gap={5}>

                                    <FormControl id="ccEmail" isRequired isInvalid={!IsValid('ccEmail', touched, errors)}>
                                        <FormLabel>Email Addres</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="ccEmail"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccEmail}
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccEmail', touched, errors), touched['ccEmail'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Contact email address
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccEmail']}</FormErrorMessage>                                
                                    </FormControl>

                                    <FormControl id="ccPhone" isRequired isInvalid={!IsValid('ccPhone', touched, errors)}>
                                        <FormLabel>Phone Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="ccPhone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccPhone}
                                                autoComplete="off"
                                                as={InputMask}
                                                mask="(999) 999-9999"
                                                maskChar=" "
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccPhone', touched, errors), touched['ccPhone'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Contact phone number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccPhone']}</FormErrorMessage>                                
                                    </FormControl>

                                </Grid>

                                <FormControl id="ccName" isRequired isInvalid={!IsValid('ccName', touched, errors)}>
                                    <FormLabel>Name On Card</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="ccName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ccName}
                                            autoComplete="off"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('ccName', touched, errors), touched['ccName'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Cardholder name on the front of the card
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['ccName']}</FormErrorMessage>                                
                                </FormControl>
                    
                                <FormControl id="ccNumber" isRequired isInvalid={!IsValid('ccNumber', touched, errors)}>
                                    <FormLabel>Card Number</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="ccNumber" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ccNumber}
                                            as={InputMask}
                                            mask="9999 9999 9999 9999"
                                            maskChar=" "
                                            data-private="redact"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('ccNumber', touched, errors), touched['ccNumber'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Card number on the front of the card
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['ccNumber']}</FormErrorMessage>
                                </FormControl>
                    
                                <Grid templateColumns="repeat(3, 1fr)" gap={{sm: 1, md: 6}}>
                    
                                    <FormControl id="ccMonth"  isRequired isInvalid={!IsValid('ccMonth', touched, errors)}>
                                        <FormLabel>Expiry Month</FormLabel>
                                        <Select 
                                            placeholder="Select Month" 
                                            name="ccMonth" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ccMonth}
                                            data-private="redact"
                                        >
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Select>
                                        <FormHelperText>
                                            Month the card expires
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccMonth']}</FormErrorMessage>
                                    </FormControl>
                    
                                    <FormControl id="ccYear" isRequired isInvalid={!IsValid('ccYear', touched, errors)}>
                                        <FormLabel>Expiry Year</FormLabel>
                                        <Select 
                                            placeholder="Select Year" 
                                            name="ccYear" 
                                            aria-describedby="ccYear-helper-text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ccYear}
                                            data-private="redact"
                                        >
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Select>
                                        <FormHelperText>
                                            Year the card expires
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccYear']}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="ccCode" isRequired isInvalid={!IsValid('ccCode', touched, errors)}>
                                        <FormLabel>Security Code</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text"                                         
                                                name="ccCode" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccCode}
                                                data-private="redact"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccCode', touched, errors), touched['ccCode'])} />
                                        </InputGroup>                                    
                                        <FormHelperText>
                                            3 digit code on the the back 
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccCode']}</FormErrorMessage>
                                    </FormControl>
                    
                                </Grid>
                    
                                <FormControl id="ccAddress" isRequired isInvalid={!IsValid('ccAddress', touched, errors)}>
                                    <FormLabel>Billing Address</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="ccAddress"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ccAddress}
                                            autoComplete="off"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('ccAddress', touched, errors), touched['ccAddress'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Cardholder billing address
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['ccAddress']}</FormErrorMessage>                                
                                </FormControl>

                                <Grid templateColumns={{sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap={6}>
                    
                                    <FormControl id="ccCity" isRequired isInvalid={!IsValid('ccCity', touched, errors)}>
                                        <FormLabel>Billing City</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="ccCity" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccCity}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccCity', touched, errors), touched['ccCity'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing city
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccCity']}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="ccState" isRequired isInvalid={!IsValid('ccState', touched, errors)}>
                                        <FormLabel>Billing State</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="ccState" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccState}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccState', touched, errors), touched['ccState'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing state
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccState']}</FormErrorMessage>
                                    </FormControl>
                    
                                    <FormControl id="ccZip" isRequired isInvalid={!IsValid('ccZip', touched, errors)}>
                                        <FormLabel htmlFor="ccZip">Billing Zip Code</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="ccZip" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.ccZip}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('ccZip', touched, errors), touched['ccZip'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing zip dode
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['ccZip']}</FormErrorMessage>
                                    </FormControl>
                    
                                </Grid>
                    
                            </Grid>

                            <hr/>

                            {displayErrors()}

                        </Box>

                        <ActionBar>
                            <Flex justify="space-between">
                                <Button
                                    size="lg"
                                    variant="outline"
                                    colorScheme="blue"
                                    isDisabled={isSubmitting}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    size="lg"
                                    colorScheme="blue"
                                    isLoading={isSubmitting}
                                    type="submit"
                                    isDisabled={!isValid}
                                >
                                    Review Payment
                                </Button>
                            </Flex>
                        </ActionBar>

                    </form>

                )
            }}
        </Formik>
    );

}

export default AutoPayFormCC;