import React from 'react';
import { Badge, Box, Flex, Grid, Heading, Link, Text } from '@chakra-ui/core';
import { getState } from 'state';
import { AnimatedHeading } from 'components/ui/Animated';
import Number from 'components/ui/Number';
import Label from 'components/ui/Label';
import { formatDate } from 'utils/dates';
import BillingMenu from 'components/ui/BillingMenu';
import { generatePDFLink } from 'utils/invoices';

const History = () => {

    const { id: profile_id, paidInvoiceList } = getState().profile;

    return (
        <Box>

            <BillingMenu active="paid"/>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="Billing History" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Review your previously paid invoices.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                <Box>
                    {paidInvoiceList.length===0?(
                        <Box bg="gray.100" p={4} borderRadius={5} mb={2}>
                            <Text color="gray.400" align="center" size="sm">No Billing History Available</Text>
                        </Box>
                    ):''}
                    {paidInvoiceList.map((invoice, key)=>{
                            
                            return (
                                <Box key={key}>

                                    <Box display={{sm:'block',md:'none'}} position="relative" layerStyle={invoice.selected?'selected':'unselected'} p={4} borderRadius={5} mb={2}>
                                        <Grid templateColumns="repeat(2, 1fr)" mb={2}>
                                            <Flex direction="column" align="center" justify="flex-start">
                                                <Label>Invoice</Label>
                                                <Text size="sm">{invoice.full_id}</Text>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="flex-end">
                                                <Label>Amount</Label>
                                                <Text size="sm" layerStyle={invoice.main_status} >
                                                    <Number value={invoice.amount} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                                </Text>
                                            </Flex>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" mb={2}>
                                            <Flex direction="column" align="center" justify="flex-start">
                                                <Label>Invoice Date</Label>
                                                <Text size="sm">{formatDate(invoice.invoice_date)}</Text>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="flex-end">
                                                <Label>Due Date</Label>
                                                <Text size="sm">{invoice.pastdue_date}</Text>
                                            </Flex>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)"> 
                                            <Flex direction="column" align="center" justify="flex-start">
                                                <Label>Status</Label>
                                                <Badge variant={invoice.main_status}>{invoice.main_status}</Badge>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="flex-end">
                                                <Label>Preview</Label>
                                                <Link size="sm" color="blue.500" lineHeight="17px" href={generatePDFLink(invoice.invoice_id, profile_id)} target="_blank" title="View Invoice" rel="noopener noreferrer">View Invoice</Link>
                                            </Flex>
                                        </Grid>
                                    </Box>

                                    <Box display={{sm:'none', md:'block'}} bg="gray.100" p={4} borderRadius={5} mb={2}>
                                        <Grid templateColumns="1fr 1fr 1fr auto 1fr auto">
                                            <Flex direction="column" align="center" justify="center">
                                                <Label>Invoice</Label>
                                                <Heading size="sm">{invoice.full_id}</Heading>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="center">
                                                <Label>Invoice Date</Label>
                                                <Heading size="sm">{formatDate(invoice.invoice_date)}</Heading>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="center">
                                                <Label>Preview</Label>
                                                <Link size="sm" color="blue.500" lineHeight="17px" href={generatePDFLink(invoice.invoice_id, profile_id)} target="_blank" title="View Invoice" rel="noopener noreferrer">View Invoice</Link>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="center">
                                                <Label>Status</Label>
                                                <Badge variant={invoice.main_status}>{invoice.main_status}</Badge>
                                            </Flex>
                                            <Flex direction="column" align="center" justify="center">
                                                <Label>Amount</Label>
                                                <Heading size="sm" layerStyle={invoice.main_status} >
                                                    <Number value={invoice.amount} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                                </Heading>
                                            </Flex>
                                        </Grid>
                                    </Box>
                                    
                                </Box>
                            )

                        }
                        
                    )}
                </Box>

                <hr/>

            </Box>

        </Box>
    );
}

export default History;