import { derived } from 'overmind'
import InvoiceClass from './InvoiceClass'
import { filterInvoicesByStatus } from './utils'
import { ProfileData } from './_types'

export type State = {
    id: string | null;
    data: ProfileData | null,
    invoiceItems: {
        [key: string]: InvoiceClass
    },
    paidInvoiceList: InvoiceClass[],
    openInvoiceList: InvoiceClass[],
    pastdueInvoiceList: InvoiceClass[],
    currentInvoiceId: string | null;
    currentInvoice: InvoiceClass | null;
}

export const state: State = {
    id: null,
    data: null,
    invoiceItems: {},
    paidInvoiceList: derived( (state: State) => filterInvoicesByStatus(state.invoiceItems, ['paid']) ),
    openInvoiceList: derived( (state: State) => filterInvoicesByStatus(state.invoiceItems, ['open','draft']) ),
    pastdueInvoiceList: derived( (state: State) => filterInvoicesByStatus(state.invoiceItems, ['pastdue','overdue']) ),
    currentInvoiceId: null,
    get currentInvoice() {
        return this.currentInvoiceId ? this.invoiceItems[this.currentInvoiceId] : null
    }
}