import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import {  getActions } from 'state';

import { PageProps } from 'screens/pages/_types';

import { AnimatedButton, AnimatedHeading } from 'components/ui/Animated';
import ActionBar from 'components/ui/ActionBar';

const UpdatedSummary: React.FC<PageProps> = ({handleNext}) => {

    const { resetState } = getActions().autopay;
    const [ isSubmitting, setSubmitting ] = React.useState(false);

    let navigate = useNavigate();
    let { profileId } = useParams();

    const handleReturn= async () => {
        setSubmitting(true);
        try {
            await resetState();
            setSubmitting(false);
            if(handleNext) {
                handleNext(()=>{
                    navigate(`/portal/${profileId}`);
                });
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="That's It! You're all set." size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Thank you. Your billing information was updated.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                <Text fontSize="xl" textStyle="bold" align="center" color="blue.500" mb={4}>Thank You!</Text>

                <Text mb={4}>The billing details on your account were succesfully updated.</Text>

                <Text mb={4}>Keep an eye on your email for our AutoPay reminders so you have a chance to preview your invoice and that month's amount due.</Text>

                <Text>If you have any further question, or would like to disable AutoPay in the future, please feel reach out to one of our cloud specialists.</Text>

                <hr/>

            </Box>

            <ActionBar>
                <Flex justify="center">
                    <AnimatedButton
                        size="lg"
                        colorScheme="blue"
                        onClick={handleReturn}
                        isLoading={isSubmitting}
                    >
                        Return To Portal Home
                    </AnimatedButton>
                </Flex>
            </ActionBar>
            
        </Box>
    );
}

export default UpdatedSummary;