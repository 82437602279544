export default {
    variants: {
        draft: {
            color: 'white',
            backgroundColor: 'blue.200'
        },
        open: {
            color: 'white',
            backgroundColor: 'blue.500'
        },
        paid: {
            color: 'white',
            backgroundColor: 'green.500'
        },
        pastdue: {
            color: 'white',
            backgroundColor: 'orange.500'
        },
        overdue: {
            color: 'white',
            backgroundColor: 'red.500'
        }
    }
}