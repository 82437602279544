import { IConfig } from 'overmind';
import { namespaced, merge } from 'overmind/config';
import { createActionsHook, createEffectsHook, createHook, createReactionHook, createStateHook } from 'overmind-react';

import { onInitialize } from './onInitialize';

import {state} from './app/state';
import * as actions from './app/actions';

import * as profile from './profile';
import payments from './payments';
import autopay from './autopay';

export const config = merge(
    {
        onInitialize,
        state,
        actions
    },
    namespaced({
        profile,
        payments,
        autopay
    })
)

declare module 'overmind' {
    interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>()
export const getState = createStateHook<typeof config>()
export const getActions = createActionsHook<typeof config>()
export const getEffects = createEffectsHook<typeof config>()
export const getReaction = createReactionHook<typeof config>() 