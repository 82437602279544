import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Text, Flex, Heading, Box, Grid } from '@chakra-ui/core';

import { getState } from 'state';

import Loader from 'components/ui/Loader';

import Payments from 'screens/Payments';
import AutoPay from 'screens/AutoPay';
import Portal from 'screens/Portal';
import History from 'screens/History';

const AppRoot = () => {
    
    const { loading, profile } = getState();

    return (
        <Grid width={{ base:'800px', sm: '100%', lg: '800px'}} mx="auto" p={3}>
            {loading?(<Loader/>):(
                <>
                    <Flex direction={{sm: 'column-reverse', md: 'row'}} align="center" justify="space-between" mb={5}>
                        <Box>
                            <Heading textAlign={{sm:'center', md:'left'}} as="h1" size={profile.data&&profile.data?.label.length>30?'md':'lg'} color={['red.500','blue.500']}>{profile.data?.label}</Heading>
                            <Text>FastPBX Account Payment Portal</Text>
                        </Box>
                        <Box mb={{sm: 2, md: 0}}>
                            <img src="https://www.fastpbx.com/assets/img/logo-dark.png" alt="FastPBX Payment Portal" />
                        </Box>
                    </Flex>
                    <Routes>
                        <Route path="*">
                            <Route path="/portal/:profileId" element={<Portal/>} />
                            <Route path="/payments/:profileId" element={<Payments/>} />
                            <Route path="/overview/:profileId" element={<Payments/>} />
                            <Route path="/autopay/:profileId" element={<AutoPay/>} />
                            <Route path="/history/:profileId" element={<History/>} />
                        </Route>
                    </Routes>
                </>
            )}
        </Grid>
    )
    
}

export default AppRoot;