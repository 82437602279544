import React from 'react';
import { Center, Grid, Text } from '@chakra-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckBoxIcon, InvoiceIcon, HistoryIcon } from 'components/ui/Icons';
import { AnimatedCard } from 'components/ui/Animated';

const Portal = () => {

    let navigate = useNavigate();
    let { profileId } = useParams();

    const navigateToScreen = (routName: string) => {
        navigate(`/${routName}/${profileId}`);
    }

    return (
        <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={{ sm: 5, md: 10 }} my={10}>
            <AnimatedCard id="navAutoPay" onClick={()=>navigateToScreen('autopay')} layerStyle="appBody" whileHover={{scale: 1.05,transition: { duration: .15 }}} _hover={{backgroundColor: 'gray.50' }} cursor="pointer">
                <Center>
                    <CheckBoxIcon boxSize={20} color="blue.500"/>
                </Center>
                <Text fontSize={25} textStyle="bold" align="center">Auto Pay</Text>
                <Text color="gray.500" align="center">Setup AutoPay On Your Account</Text>
            </AnimatedCard>
            <AnimatedCard id="navBillPay" onClick={()=>navigateToScreen('payments')} layerStyle="appBody" whileHover={{scale: 1.05,transition: { duration: .15 }}} _hover={{backgroundColor: 'gray.50' }} cursor="pointer">
                <Center>
                    <InvoiceIcon boxSize={20} color="blue.500"/>
                </Center>
                <Text fontSize={25} textStyle="bold" align="center">Bill Pay</Text>
                <Text color="gray.500" align="center">View & Pay Account Invoices</Text>
            </AnimatedCard>
            <AnimatedCard id="navBillPay" onClick={()=>navigateToScreen('history')} layerStyle="appBody" whileHover={{scale: 1.05,transition: { duration: .15 }}} _hover={{backgroundColor: 'gray.50' }} cursor="pointer">
                <Center>
                    <HistoryIcon boxSize={20} color="blue.500"/>
                </Center>
                <Text fontSize={25} textStyle="bold" align="center">Billing History</Text>
                <Text color="gray.500" align="center">Review Previously Paid Invoices</Text>
            </AnimatedCard>
        </Grid>
    );
}

export default Portal;