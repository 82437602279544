import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/core';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import semver from './version.json';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './style/scss/theme.scss';
import { customTheme } from 'style/chakra-theme';

const chakraTheme = extendTheme(customTheme);

Sentry.init({
    dsn: "https://9997428a1e0043cd9e1a5b4083451617@o89014.ingest.sentry.io/5458795",
    environment: process.env.REACT_APP_APP_ENV || process.env.NODE_ENV,
    release: semver.version,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={chakraTheme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
