import { derived, RootState } from "overmind"
import InvoiceClass from "state/profile/InvoiceClass";
import { InvoiceToPay } from "./_types";
import { VerifyError, GenericError } from "../_types";

export type State = {
    amount: number;
    token: string | null;
    tokenDescriptor: string | null;
    tokenErrors: VerifyError[]; 
    chargeErrors: GenericError[]; 
    current: string | number;
    invoices: InvoiceToPay[];
    invoiceList: InvoiceClass[],
    termsAccepted: boolean;
    chargeAuthorized: boolean;
    chargeSuccess: boolean;
    verificationCode: string | null;
    paymentMode: 'cc' | 'ach',
    billing: {
        ccFirstName: string | null;
        ccLastName: string | null;
        ccAddress: string | null;
        ccCity: string | null;
        ccState: string | null;
        ccZip: string | null;
        achAccountName: string | null;
        achAccountNumber: string | null;
        achRoutingNumber: string | null;
        achAccountType: string | null;
        achFirstName: string | null;
        achLastName: string | null;
        achAddress: string | null;
        achCity: string | null;
        achState: string | null;
        achZip: string | null;
    }
}

export const state: State = {
    token: null,
    tokenDescriptor: null,
    tokenErrors: [],
    chargeErrors: [],
    invoices: [],
    termsAccepted: false,
    chargeAuthorized: false,
    chargeSuccess: false,
    verificationCode: null,
    paymentMode: 'cc',
    billing: {
        ccFirstName: null,
        ccLastName: null,
        ccAddress: null,
        ccCity: null,
        ccState: null,
        ccZip: null,
        achAccountName: null,
        achAccountNumber: null,
        achRoutingNumber: null,
        achAccountType: null,
        achFirstName: null,
        achLastName: null,
        achAddress: null,
        achCity: null,
        achState: null,
        achZip: null,
    },
    invoiceList: derived( (state: State, root: RootState) => {
        return state.invoices.reduce((selectedInvoices, invoice)=>{
            if (root.profile.invoiceItems.hasOwnProperty(invoice.id)) {
                return [...selectedInvoices, root.profile.invoiceItems[invoice.id]];
            } else {
                return selectedInvoices;
            }
        }, [] as InvoiceClass[],);
    }),
    amount: derived( (state: State, root: RootState) => {
        return Object.values(root.profile.invoiceItems).reduce((amount, invoice)=>{
            if (invoice.status !== 'paid' && invoice.selected) {
                let newAmount = amount + invoice.balance;
                return newAmount;
            } else {
                return amount + 0;
            }
        }, 0);
    }),
    current: derived( (state: State, root: RootState) => root.payments.states[0][1] ),
}