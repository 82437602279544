export const capitalizeWord = (value: string): string => {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const extractNameParts = (fullName: string): { firstName: string, lastName: string } => {
    // Split Up Name
    let nameParts = fullName.split(' ');
    // Get First Name
    let firstName = nameParts.shift() || '';
    let lastName = '';
    // If Multiple Name Parts Remain, Could Be Long Last Name
    if (nameParts.length > 1) {
        // Merge Remaining Name Parts Into Long Last Name
        lastName = nameParts.join(' ');
    } else if ( nameParts.length ) {
        // Return Single Last Name Part
        lastName = nameParts.pop() || '';
    } else {
        lastName = '';
    }
    // Return Name Parts
    return { firstName, lastName };
}
