import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/core';
import semver from '../version.json';

const Home = () => {
    return (
        <Flex direction="column" align="center" justify="center" p={5} height="100vh">
            <img src="https://www.fastpbx.com/assets/img/logo-dark.png" alt="FastPBX Payment Portal" />
            <Heading as="h1" size="md" color="blue.500" my={3}>FastPBX Payment Portal</Heading>
            <Text size="sm" color="gray.500">v{semver.version}</Text>
        </Flex>
    );
}

export default Home;