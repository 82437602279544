import { Statechart, statechart } from 'overmind-statechart';

import { state } from './state';
import * as actions from './actions';
import * as effects from './effects';

const config = {
    state,
    actions,
    effects
}

const autoPayFlowChart: Statechart<typeof config, {
    OVERVIEW: void
    UPDATE: void
    FORM: void
    REVIEW: void
    SUMMARY: void
}> = {
    initial: 'OVERVIEW',
    states: {
        OVERVIEW: {
            entry: 'checkModeCode',
            on: {
                next: {
                    target: 'FORM',
                    condition: state => !state.autoPayEnabled || !state.autoPayConfigured
                },
                startUpdateFlow: 'UPDATE'
            }
        },
        UPDATE: {
            on: {
                next: 'FORM'
            }
        },
        FORM: {
            entry: 'viewedForm',
            on: {
                back: 'OVERVIEW',
                getPaymentTokenCC: null,
                getPaymentTokenACH: null,
                next: {
                    target: 'REVIEW',
                    condition: state => !!state.token
                }
            }
        },
        REVIEW: {
            entry: 'viewedReview',
            on: {
                back: 'OVERVIEW',
                acceptTerms: null,
                authorizeAutoPay: null,
                resetErrors: null,
                submitAutoPay: {
                    target: 'REVIEW',
                    condition: state => state.termsAccepted && state.autoPayAuthorized
                },
                next: {
                    target: 'SUMMARY',
                    condition: state => state.signUpSuccess
                }
            }
        },
        SUMMARY: {
            entry: 'viewedSummary',
            on: {
                resetState: null,
                next: 'OVERVIEW'
            }
        }
    }
}

export default statechart(config, autoPayFlowChart);