import { createIcon } from '@chakra-ui/core';

export const CheckIcon = createIcon({
    displayName: "CheckIcon",
    viewBox: "0 0 20 20",
    d: "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
})

export const CrossIcon = createIcon({
    displayName: "CrossIcon",
    viewBox: "0 0 20 20",
    d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
})

export const CheckBoxIcon = createIcon({
    displayName: "CheckBoxIcon",
    viewBox: "0 0 32 32",
    d: "M5 5v22h22V5zm2 2h18v18H7zm14.281 4.281L14 18.562l-3.281-3.28l-1.438 1.437l4 4l.719.687l.719-.687l8-8z"
})

export const InvoiceIcon = createIcon({
    displayName: "InvoiceIcon",
    viewBox: "0 0 32 32",
    d: "M6 3v26h20V9.6l-.3-.3l-6-6l-.3-.3H6zm2 2h10v6h6v16H8V5zm12 1.4L22.6 9H20V6.4zM10 13v2h12v-2H10zm0 5v2h7v-2h-7zm9 0v2h3v-2h-3zm-9 4v2h7v-2h-7zm9 0v2h3v-2h-3z"
})

export const HistoryIcon = createIcon({
    displayName: "HistoryIcon",
    viewBox: "0 0 32 32",
    d: "M16 4A11.989 11.989 0 0 0 6 9.344V6H4v7h7v-2H7.375C9.102 8.02 12.297 6 16 6c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16H4c0 6.617 5.383 12 12 12s12-5.383 12-12S22.617 4 16 4zm-1 4v9h7v-2h-5V8z"
})