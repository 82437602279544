import React from 'react';
import { Box, Text, useDisclosure, ModalHeader, ModalFooter, ModalContent, ModalOverlay, Modal, ModalBody, Alert, AlertDescription, AlertIcon, AlertTitle, Stack, Flex } from "@chakra-ui/core";
import { getActions, getReaction, getState } from 'state';
import { useAnimation } from 'framer-motion';

import InvoiceList from 'components/invoices/InvoiceList';
import { AnimatedButton, AnimatedCard, AnimatedHeading, AnimatedText } from 'components/ui/Animated';
import { PageProps } from '../_types';
import BillingMenu from 'components/ui/BillingMenu';
import { useNavigate, useParams } from 'react-router-dom';
import ActionBar from 'components/ui/ActionBar';

const Invoices: React.FC<PageProps> = ({handleNext}) => {

    const { openInvoiceList, pastdueInvoiceList, data: profileData } = getState().profile;
    const { autoPayEnabled, autoPayConfigured } = getState().autopay;
    const { amount } = getState().payments;
    const { selectInvoices, selectPaymentType } = getActions().payments;
    const reaction = getReaction();
    const controls = useAnimation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    let { profileId } = useParams();
    
    const achDisabled = profileData && profileData.achPaymentsDisabled;

    React.useEffect(() => {
        return reaction(
            (state) => state.payments.amount,
            () => {
                controls.start({
                    scale: [1, 1.5, 1]
                })
            }
        )
    }, [reaction, controls]);

    const onNext = async () => {
        await selectInvoices();
        if(handleNext) {
            handleNext();
        }
    }

    const handleOneTimeChargeCC = async () => {
        await selectPaymentType('cc');
        onClose();
        onNext();
    }

    const handleOneTimeChargeACH = async () => {
        await selectPaymentType('ach');
        onClose();
        onNext();
    }

    const handleUpdateBilling = () => {
        onClose();
        navigate(`/autopay/${profileId}`);
    }

    return (
        <React.Fragment>

            <BillingMenu active="open"/>

            <Box layerStyle="appBody" mb={5}> 
                <Box>
                    <AnimatedHeading title="Open Invoices" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">These invoices are currently outstanding. Payments should be made by the due date.</Text>
                    </AnimatedHeading>
                </Box>
                <hr/>
                <Box>
                    <InvoiceList invoices={openInvoiceList} />
                </Box>
                <hr/>
            </Box>

            {pastdueInvoiceList.length?(
                <Box layerStyle="appBody" mb={5}>
                    <Box>
                        <AnimatedHeading title="Late Invoices" size="md">
                            <Text color="gray.500" fontSize="sm" align="center">These invoices are late and are subject to late processing fees.</Text>
                        </AnimatedHeading>
                    </Box>
                    <hr/>
                    <Box>
                        <InvoiceList invoices={pastdueInvoiceList} />
                    </Box>
                    <hr/>
                </Box>
            ):''}

            <ActionBar>
                <Flex justify="space-between" align="center">

                    <Flex align="center">
                        <Text fontSize={{sm:'lg',md:'3xl'}} textStyle="bold" mr={2}>Amount Due:</Text>
                        <AnimatedText animate={controls} color="green.700" fontSize={{sm:'lg',md:'3xl'}} textStyle="bold">${amount.toFixed(2)}</AnimatedText>
                    </Flex>

                    <AnimatedButton
                        onClick={onOpen}
                        size="lg"
                        colorScheme="blue"
                        animate={{ scale: [1, 1.5, 1] }}
                        isDisabled={!amount}
                    >
                        Pay Now
                    </AnimatedButton>

                </Flex>
            </ActionBar>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalBody>

                        {autoPayConfigured&&autoPayEnabled?(
                            <Alert
                                status="success"
                                variant="subtle"
                                flexDirection="column"
                                alignItems="flex-start"
                                mb={3}
                            >
                                <Stack direction="row" justify="flex-start" mb={3}>
                                    <AlertIcon boxSize="25px" mr={1} />
                                    <AlertTitle>AutoPay is Enabled!</AlertTitle>
                                </Stack>
                                <AlertDescription display="block">
                                    <Text fontSize="sm" mb={3}>We will automatically attempt to charge any open invoices with the billing details we have on record.</Text>
                                    <Text fontSize="sm">If you'd like, you can click below to make and instant payment and resolve the currently selected invoices.</Text>
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <AnimatedCard 
                                id="navAutoPay" 
                                onClick={()=>handleUpdateBilling()} 
                                bg="gray.50" 
                                borderRadius={6} 
                                shadow="sm" 
                                padding={6} 
                                whileHover={{scale: 1.05,transition: { duration: .15 }}} 
                                _hover={{backgroundColor: 'gray.100' }} 
                                cursor="pointer"
                                mb={3}
                                borderColor="gray.200"
                                borderWidth={1}
                            >
                                <Text fontSize={20} textStyle="bold" align="center">Activate AutoPay</Text>
                                <Text color="gray.500" align="center">To setup automatic payments</Text>
                            </AnimatedCard>
                        )} 
                            <AnimatedCard 
                                id="navAutoPayCC" 
                                onClick={()=>handleOneTimeChargeCC()} 
                                bg="gray.50" 
                                borderRadius={6} 
                                shadow="sm" 
                                padding={6} 
                                whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                _hover={{backgroundColor: 'gray.100' }} 
                                cursor="pointer"
                                mb={3}
                                borderColor="gray.200"
                                borderWidth={1}
                            >
                                <Text fontSize={20} textStyle="bold" align="center">Pay Now With Credit Card</Text>
                                <Text color="gray.500" align="center">To make an instant payment</Text>
                            </AnimatedCard>
                            {!achDisabled&&(
                                <AnimatedCard 
                                    id="navAutoPayACH" 
                                    onClick={()=>handleOneTimeChargeACH()} 
                                    bg="gray.50" 
                                    borderRadius={6} 
                                    shadow="sm" 
                                    padding={6} 
                                    whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                    _hover={{backgroundColor: 'gray.100' }} 
                                    cursor="pointer"
                                    borderColor="gray.200"
                                    borderWidth={1}
                                >
                                    <Text fontSize={20} textStyle="bold" align="center">Pay Now With Bank Account</Text>
                                    <Text color="gray.500" align="center">To make an ACH payment</Text>
                                </AnimatedCard>
                            )}
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
            
        </React.Fragment>
    );
}

export default Invoices;