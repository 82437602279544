import React from 'react';
import { Box, Text, Flex, Alert, AlertDescription, AlertIcon, AlertTitle, Stack, useDisclosure, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import { PageProps } from 'screens/pages/_types';

import { AnimatedButton, AnimatedCard, AnimatedHeading } from 'components/ui/Animated';
import { getActions, getState } from 'state';
import ActionBar from 'components/ui/ActionBar';

const Overview: React.FC<PageProps> = ({handleNext}) => {

    const { data: profileData } = getState().profile;
    const { autoPayEnabled, autoPayConfigured } = getState().autopay;
    const { selectPaymentType } = getActions().payments;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const achDisabled = profileData && profileData.achPaymentsDisabled;

    const handleAutoPayCC = async () => {
        await selectPaymentType('cc');
        onClose();
        if(handleNext) {
            handleNext();
        }
    }

    const handleAutoPayACH = async () => {
        await selectPaymentType('ach');
        onClose();
        if(handleNext) {
            handleNext();
        }
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="FastPBX AutoPay" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">The easiest way to stay up to date on your payments.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                {autoPayConfigured?(
                    <Alert
                        status="success"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="flex-start"
                        mb={3}
                    >
                        <Stack direction="row" justify="flex-start" mb={3}>
                            <AlertIcon boxSize="25px" mr={1} />
                            <AlertTitle>AutoPay is Enabled!</AlertTitle>
                        </Stack>
                        <AlertDescription display="block">
                            <Text fontSize="md" mb={3}>We will automatically attempt to charge any open invoices with the billing details we have on record.</Text>
                            <Text fontSize="md">If you have any questions, or to update your billing information at any time, please give us a call at <strong><a href="tel:18889885355" title="FastPBX 24/7 US Based Support">888-988-5355</a></strong>.</Text>
                        </AlertDescription>
                    </Alert>
                ) : (
                    <React.Fragment>
                        <Text fontSize="xl" textStyle="bold" align="center" mb={4} color="blue.500">Never miss a payment again!</Text>
                        <Text align="center" mb={4}>
                            Getting started with  <strong>AutoPay</strong> is quick and easy. To activate your automatic payments, simply sign up with your preferred payment type and we'll handle the rest.
                        </Text>
                        <Text fontSize="xl" textStyle="bold" align="center" mb={4}>It's that easy!</Text>
                    </React.Fragment>
                )}
                
                <hr/>

            </Box>

            <ActionBar>
                <Flex justify="center" align="center">

                    <AnimatedButton
                        onClick={onOpen}
                        size="lg"
                        colorScheme="blue"
                        animate={autoPayEnabled||autoPayConfigured?{}:{ scale: [1, 1.5, 1] }}
                        isDisabled={autoPayEnabled||autoPayConfigured}
                    >
                        Sign Up For AutoPay
                    </AnimatedButton>

                </Flex>
            </ActionBar>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalBody>

                            <AnimatedCard 
                                id="navAutoPayCC" 
                                onClick={()=>handleAutoPayCC()} 
                                bg="gray.50" 
                                borderRadius={6} 
                                shadow="sm" 
                                padding={6} 
                                whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                _hover={{backgroundColor: 'gray.100' }} 
                                cursor="pointer"
                                mb={3}
                                borderColor="gray.200"
                                borderWidth={1}
                            >
                                <Text fontSize={20} textStyle="bold" align="center">Use Credit Card</Text>
                                <Text color="gray.500" align="center">Use a credit card for automatic payments.</Text>
                            </AnimatedCard>
                            {!achDisabled&&(
                                <AnimatedCard 
                                    id="navAutoPayACH" 
                                    onClick={()=>handleAutoPayACH()} 
                                    bg="gray.50" 
                                    borderRadius={6} 
                                    shadow="sm" 
                                    padding={6} 
                                    whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                    _hover={{backgroundColor: 'gray.100' }} 
                                    cursor="pointer"
                                    borderColor="gray.200"
                                    borderWidth={1}
                                >
                                    <Text fontSize={20} textStyle="bold" align="center">Use Bank Account</Text>
                                    <Text color="gray.500" align="center">Use ACH for automatic payments.</Text>
                                </AnimatedCard>
                            )}
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>

        </Box>
    );
}

export default Overview;