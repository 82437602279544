import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { ProfileHubResponse } from 'state/profile/_types';
import { AutoPayPayload } from 'state/autopay/_types';

const AUTOPAY_ENDPOINT_URL = process.env.REACT_APP_AUTOPAY_ENDPOINT_URL;

export const submitAutoPayForm = async (payload: AutoPayPayload): Promise<boolean> => {

    return new Promise((resolve,reject)=>{

        if ( !AUTOPAY_ENDPOINT_URL ) {
            Sentry.captureMessage('002: No AutoPay Endpoint Configured');
            return reject([{code: '002', text:'No AutoPay Endpoint Configured'}]);
        }
        
        axios.post<ProfileHubResponse<boolean>>(AUTOPAY_ENDPOINT_URL, payload)
            .then((res) => {
                if ( res.data.message.success ) {
                    return resolve(true);
                } else {
                    if (res.data.message.hasOwnProperty('errors')) {
                        return reject(res.data.message.errors);
                    } else if (typeof res.data.message === 'string') {
                        let erroString = res.data.message as string;
                        if ( erroString.startsWith('Sorry') ) {
                            Sentry.captureMessage('[104] ProfileHub Business Endpoint Error');
                            return reject([{code:'104', text:'API Backend Error'}]);
                        }
                    } else {
                        Sentry.captureMessage('[103] ProfileHub Business Endpoint Error: ' + JSON.stringify(res.data));
                        return reject([{code:'103', text:'API Backend Error'}]);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                return reject([{code: '102', text:'API Backend Error'}]);
            });

    });

}