import { FormikTouched, FormikErrors } from 'formik';

export const IsValid = <T>(key: keyof T, touched: FormikTouched<T>, errors: FormikErrors<T>): boolean => {
    let isTouched = touched.hasOwnProperty(key);
    let hasError = errors.hasOwnProperty(key);
    if (!hasError||!isTouched) return true;
    if (isTouched&&hasError) return false;
    return false;
}

export const FORM_PHONE_VALIDATION_REGEX = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;