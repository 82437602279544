export default {
    baseStyle: {
        textTransform: "uppercase",
        color: "gray.400",
        textAlign: "left",
        marginBottom: "4px"
    },
    sizes: {
        sm: {
            fontSize: "12px"
        }
    },
    defaultProps: {
        size: "sm"
    },
}