import InvoiceClass from "./InvoiceClass";
import { State } from "./state";
import { InvoiceMainStatus } from "./_types";

export const filterInvoicesByStatus = (invoices: State['invoiceItems'], statuses: InvoiceMainStatus[]): InvoiceClass[] => {
    return Object.values(invoices).filter((invoice)=>{
        return statuses.some((status)=>{
            return invoice.main_status === status;
        });
    });
}