import React from 'react';
import { Flex, Link } from '@chakra-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

type BillingMenuProps = {
    active: 'open' | 'paid';
}

const BillingMenu: React.FC<BillingMenuProps> = ({active}) => {

    let navigate = useNavigate();
    let { profileId} = useParams();

    const onNavigation = (route: 'open' | 'paid') => {
        let destination = null;
        switch(route) {
            case 'open':
                destination = `/overview/${profileId}`;
                break;
            case 'paid':
                destination = `/history/${profileId}`;
                break;
        }
        if (active!==route) {
            navigate(destination)
        }
    }

    return (
        <Flex bg="gray.600" justify="center" borderRadius={6} mb={4}>
            <Link color="white" fontSize={{sm:12, md:'initial'}} py={2} px={4} m={2} borderRadius={6} borderWidth={1} borderColor={active==='open'?'white':'gray.600'} onClick={()=>onNavigation('open')}><strong>Current Invoices</strong></Link>
            <Link color="white" fontSize={{sm:12, md:'initial'}} py={2} px={4} m={2} borderRadius={6} borderWidth={1} borderColor={active==='paid'?'white':'gray.600'} onClick={()=>onNavigation('paid')}><strong>Billing History</strong></Link>
        </Flex>
    )
}

export default BillingMenu;