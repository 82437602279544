import React from 'react';
import { Box, Text, Flex, Grid, Heading, Checkbox, Center, Button, Alert, Stack, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/core';
import { useAnimation } from 'framer-motion';

import { PageProps } from 'screens/pages/_types';
import { GenericError } from 'state/_types';

import { AnimatedButton, AnimatedHeading, AnimatedText } from 'components/ui/Animated';
import { getActions, getReaction, getState } from 'state';

import { formatDate } from 'utils/dates';

import Number from 'components/ui/Number';
import Label from 'components/ui/Label';
import ActionBar from 'components/ui/ActionBar';

const POLICY_URL = 'https://www.fastpbx.com/privacy-policy';

const Review: React.FC<PageProps> = ({handleBack, handleNext}) => {

    const { amount, invoiceList, termsAccepted, chargeAuthorized, chargeErrors, paymentMode } = getState().payments;
    const { acceptTerms, authorizeCharge, submitTransactionCC, submitTransactionACH, resetErrors } = getActions().payments;
    const reaction = getReaction();
    const controls = useAnimation();
    const [ isSubmitting, setSubmitting ] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    React.useEffect(() => {
        return reaction(
            (state) => state.payments.termsAccepted && state.payments.chargeAuthorized,
            (ready) => {
                if (ready) {
                    controls.start({
                        scale: [1, 1.25, 1]
                    })
                }            
            }
        )
    }, [reaction, controls]);

    const handleTermsAccepted = (status: boolean) => {
        acceptTerms(status);
    }
    
    const handleChargeAuthorized = (status: boolean) => {
        authorizeCharge(status);
    }

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            if (paymentMode==='cc') {
                await submitTransactionCC();
            } else {
                await submitTransactionACH();
            }
            setSubmitting(false);
            if(handleNext) {
                handleNext();
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    const onBack = () => {
        resetErrors();
        if(handleBack) {
            handleBack();
        }
    }

    const displayErrors = () => {
        if (!chargeErrors.length) return null;
        return (
            <Stack spacing={3}>
                {chargeErrors.map((error: GenericError, key)=>{
                    return (
                        <Alert status="error" variant="left-accent" key={key}>
                            <strong>{error.code}:</strong>{' '}<span>{error.text}</span>
                        </Alert>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="Review Payment" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Please review and verify your pending payment.</Text>
                    </AnimatedHeading>
                </Box>
                
                <hr/>

                <Box>
                    <Grid templateColumns={{sm:'1fr 1fr 1fr 1fr',md:'1fr 1fr 1fr 1fr 1fr'}} >
                        <Flex align="center" justify="flex-start">
                            <Label>Status</Label>
                        </Flex>
                        <Flex align="center" justify="center">
                            <Label>Invoice</Label>
                        </Flex>
                        <Flex align="center" justify="center">
                            <Label>Invoice Date</Label>
                        </Flex>
                        <Flex align="center" justify="center" display={{sm:'none',md:'block'}}>
                            <Label>Due Date</Label>
                        </Flex>
                        <Flex align="center" justify="flex-end">
                            <Label>Balance</Label>
                        </Flex>
                    </Grid>
                </Box>
                <Box>
                    {invoiceList.map((invoice, key)=>{
                        return (
                            <Box key={key} mb={1}>
                                <Grid templateColumns={{sm:'1fr 1fr 1fr 1fr',md:'1fr 1fr 1fr 1fr 1fr'}} >
                                    <Flex align="center" justify="flex-start">
                                        <Heading size="sm" variant={invoice.main_status}>{invoice.main_status}</Heading>
                                    </Flex>
                                    <Flex align="center" justify="center">
                                        <Text size="sm">{invoice.full_id}</Text>
                                    </Flex>
                                    <Flex align="center" justify="center">
                                        <Text size="sm">{formatDate(invoice.invoice_date)}</Text>
                                    </Flex>
                                    <Flex align="center" justify="center" display={{sm:'none',md:'block'}}>
                                        <Text size="sm">{invoice.pastdue_date}</Text>
                                    </Flex>
                                    <Flex align="center" justify="flex-end">
                                        <Heading size="sm">
                                            <Number value={invoice.balance} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                        </Heading>
                                    </Flex>
                                </Grid>
                            </Box>
                        )
                    })}
                </Box>

                <hr/>

                <Box mb={4}>
                    <Text fontSize="lg" align="center" textStyle="bold">Amount To Be Charged: </Text>
                    <AnimatedText animate={{scale: [1, 1.5, 1]}} color="green.700" fontSize="3xl" align="center" textStyle="bold">${amount.toFixed(2)}</AnimatedText>
                </Box>

                <hr/>

                <Center>
                    <Flex direction="column">
                        <Checkbox 
                            size="md" 
                            name="acceptChargeTerms"
                            isChecked={termsAccepted}
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleTermsAccepted(e.target.checked) }
                            mb={3}
                        > 
                            <Text as="span">{`I have read and accept the `}</Text>
                            <Link color="blue.500" href="#" onClick={onOpen} title="FastPBX Payment Terms" rel="noopener noreferrer">FastPBX Payment Terms</Link>
                            <Text as="span">{` & `}</Text>
                            <Link color="blue.500" href={POLICY_URL} target="_blank" title="FastPBX Privacy Policy" rel="noopener noreferrer">Privacy Policy</Link>
                        </Checkbox>
                        <Checkbox 
                            size="md" 
                            name="authorizeCharge"
                            isChecked={chargeAuthorized}
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleChargeAuthorized(e.target.checked) }
                        > I authorize <strong>FastPBX</strong> to charge the {paymentMode==='cc'?'card':'account'} provided in the amount of <strong><Number value={amount} prefix="$" decimalScale={2} fixedDecimalScale={true}/></strong></Checkbox>
                    </Flex>
                </Center>

                <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
                    <ModalOverlay>
                        <ModalContent>
                            <ModalHeader>
                                <Text>Payment Terms</Text>
                                <ModalCloseButton />
                            </ModalHeader>
                            <ModalBody>
                                <Text>I authorize FastPBX / Dynamic Packet Corporation to charge the credit card or ACH account indicated in the authorization form according. I understand that the payment will be authorized and charged at this time. I certify that I am an authorized user of this account and that I will not dispute charges with my credit card company or banking institution provided that the transaction corresponds to the terms indicated in the authorization form.</Text>
                            </ModalBody>
                            <ModalFooter></ModalFooter>
                        </ModalContent>
                    </ModalOverlay>
                </Modal>

                <hr/>

                {displayErrors()}

            </Box>

            <ActionBar>
                <Flex justify="space-between">
                    <Button
                        size="lg"
                        variant="outline"
                        colorScheme="blue"
                        onClick={onBack}
                        isDisabled={isSubmitting}
                    >
                        Back
                    </Button>
                    <AnimatedButton
                        size="lg"
                        animate={controls}
                        colorScheme="green"
                        onClick={handleSubmit}
                        isDisabled={termsAccepted && chargeAuthorized ? false : true}
                        isLoading={isSubmitting}
                    >
                        Submit Payment
                    </AnimatedButton>
                </Flex>
            </ActionBar>

        </Box>
    );
}

export default Review;