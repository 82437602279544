type State = {
    loading: boolean;
    route: string;
    code: string | null;
}

export const state: State = {
    loading: false,
    route: 'overview',
    code: null
}