import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { generateDeviceId } from 'utils/env';
import { PaymentFormDataCC, PaymentFormDataACH,  PaymentPayload, SubmitPaymentResponse } from 'state/payments/_types';
import { AuthPayloadCC, AuthPayloadACH, AuthResponse, AuthData, } from 'state/_types';
import { ProfileHubResponse } from 'state/profile/_types';

declare global {
    interface Window { 
        Accept: {
            dispatchData(data: AuthPayloadCC | AuthPayloadACH, handler: (res: AuthResponse) => void): any
        }
    }
}

export const fetchPaymentTokenCC = async (payload: PaymentFormDataCC): Promise<AuthData> => {
    
    const authData = {
        clientKey: process.env.REACT_APP_PAY_PUB_KEY,
        apiLoginID: process.env.REACT_APP_TEMP_TIMESTAMP
    }

    const cardData = {
        fullName: payload.ccName,
        cardNumber: payload.ccNumber,
        month: payload.ccMonth,
        year: payload.ccYear,
        cardCode: payload.ccCode,
        zip: payload.ccZip,
    }

    return new Promise((resolve, reject)=>{ 
        window.Accept.dispatchData({ authData, cardData }, (response) => {
            if (response.messages.resultCode === 'Error') {
                Sentry.captureMessage('Accept.js Error: ' + JSON.stringify(response.messages.message));
                return reject(response.messages.message);
            } else {
                return resolve(response.opaqueData);
            }
        });
    });

}

export const fetchPaymentTokenACH = async (payload: PaymentFormDataACH): Promise<AuthData> => {
    
    const authData = {
        clientKey: process.env.REACT_APP_PAY_PUB_KEY,
        apiLoginID: process.env.REACT_APP_TEMP_TIMESTAMP
    }

    const bankData = {
        accountNumber: payload.achAccountNumber,
        routingNumber: payload.achRoutingNumber,
        nameOnAccount: payload.achAccountName,
        accountType: payload.achAccountType
    }

    return new Promise((resolve, reject)=>{ 
        window.Accept.dispatchData({ authData, bankData }, (response) => {
            if (response.messages.resultCode === 'Error') {
                Sentry.captureMessage('Accept.js Error: ' + JSON.stringify(response.messages.message));
                return reject(response.messages.message);
            } else {
                return resolve(response.opaqueData);
            }
        });
    });

}

const BILLPAY_ENDPOINT_URL = process.env.REACT_APP_BILLPAY_ENDPOINT_URL;

export const submitPayment = async (payload: PaymentPayload): Promise<SubmitPaymentResponse> => {

    return new Promise((resolve,reject)=>{

        if ( !BILLPAY_ENDPOINT_URL ) {
            Sentry.captureMessage('003: No BillPay Endpoint Configured');
            return reject([{code: '003', text:'No BillPay Endpoint Configured'}]);
        }

        axios.post<ProfileHubResponse<SubmitPaymentResponse>>(BILLPAY_ENDPOINT_URL, payload, { headers: { 'device-id': generateDeviceId() } })
            .then((res) => {
                if ( res.data.message.success && res.data.message.data) {
                    return resolve(res.data.message.data); 
                } else {
                    if (res.data.message.hasOwnProperty('errors')) {
                        return reject(res.data.message.errors);
                    } else if (typeof res.data.message === 'string') {
                        let erroString = res.data.message as string;
                        if ( erroString.startsWith('Sorry') ) {
                            Sentry.captureMessage('[104] ProfileHub Business Endpoint Error');
                            return reject([{code:'104', text:'API Backend Error'}]);
                        }
                    } else {
                        Sentry.captureMessage('[103] ProfileHub Business Endpoint Error: ' + JSON.stringify(res.data));
                        return reject([{code:'103', text:'API Backend Error'}]);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                return reject([{code: '102', text:'API Backend Error'}]);
            });

    });

}