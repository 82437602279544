import { Box } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import React from 'react';

const ActionBar: React.FC = ({children}) => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{ delay: 1 }}>
            <Box bg="white" p={5} position="fixed" bottom={0} left={0} right={0}>
                <Box width={{ base:'800px', sm: '100%', lg: '800px'}} mx="auto">
                    {children}
                </Box>
            </Box>
        </motion.div>
    )
}

export default ActionBar;