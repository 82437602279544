import React from 'react';
import { Box, Text, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/core';
import { PageProps } from 'screens/pages/_types';

import { AnimatedButton, AnimatedCard, AnimatedHeading } from 'components/ui/Animated';
import { getActions, getState } from 'state';
import ActionBar from 'components/ui/ActionBar';

const Update: React.FC<PageProps> = ({handleNext}) => {

    const { data: profileData } = getState().profile;
    const { selectPaymentType } = getActions().payments;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const achDisabled = profileData && profileData.achPaymentsDisabled;

    const handleAutoPayCC = async () => {
        await selectPaymentType('cc');
        onClose();
        if(handleNext) {
            handleNext();
        }
    }

    const handleAutoPayACH = async () => {
        await selectPaymentType('ach');
        onClose();
        if(handleNext) {
            handleNext();
        }
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="Update Billing Details" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Update the billing information on your account.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                <Text align="center" mb={4}>
                    Update the billing information on your account to stay up to date with your monthly payments.
                </Text>

                <hr/>

            </Box>

            <ActionBar>
                <Flex justify="center" align="center">
                    <AnimatedButton
                        onClick={onOpen}
                        size="lg"
                        colorScheme="blue"
                        animate={{ scale: [1, 1.5, 1] }}
                    >
                        Update Billing
                    </AnimatedButton>
                </Flex>
            </ActionBar>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalBody>

                            <AnimatedCard 
                                id="navAutoPayCC" 
                                onClick={()=>handleAutoPayCC()} 
                                bg="gray.50" 
                                borderRadius={6} 
                                shadow="sm" 
                                padding={6} 
                                whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                _hover={{backgroundColor: 'gray.100' }} 
                                cursor="pointer"
                                mb={3}
                                borderColor="gray.200"
                                borderWidth={1}
                            >
                                <Text fontSize={20} textStyle="bold" align="center">Use Credit Card</Text>
                                <Text color="gray.500" align="center">Use a credit card for automatic payments.</Text>
                            </AnimatedCard>
                            {!achDisabled&&(
                                <AnimatedCard 
                                    id="navAutoPayACH" 
                                    onClick={()=>handleAutoPayACH()} 
                                    bg="gray.50" 
                                    borderRadius={6} 
                                    shadow="sm" 
                                    padding={6} 
                                    whileHover={{scale: 1.025,transition: { duration: .15 }}} 
                                    _hover={{backgroundColor: 'gray.100' }} 
                                    cursor="pointer"
                                    borderColor="gray.200"
                                    borderWidth={1}
                                >
                                    <Text fontSize={20} textStyle="bold" align="center">Use Bank Account</Text>
                                    <Text color="gray.500" align="center">Use ACH for automatic payments.</Text>
                                </AnimatedCard>
                            )}
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>

        </Box>
    );
}

export default Update;