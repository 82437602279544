import React from 'react';
import NumberFormat from "react-number-format";

type NumberProps = {
    value: number;
    prefix?: string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    suffix?: string;
    style?: any;
}

const Number: React.FC<NumberProps> = ({ value, prefix, decimalScale, suffix, fixedDecimalScale, ...props }) => {
    return (
        <NumberFormat
            displayType='text'
            value={value}
            thousandSeparator=','
            suffix={suffix}
            prefix={prefix}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            renderText={(value: string) => <span {...props}>{value}</span>}
        />
    );
};

Number.defaultProps = {
    value: 0,
    prefix: '',
    suffix: '',
    style: {},
    decimalScale: 0,
};

export default Number;