import { Action, AsyncAction } from 'overmind';
import { AutoPayFormDataCC, AutoPayFormDataACH, AutoPayPayload } from 'state/autopay/_types';
import { extractNameParts } from 'utils/strings';

export const getPaymentTokenCC: AsyncAction<AutoPayFormDataCC> = async ({ state, effects }, payload) => {
    try {

        // Replace WhiteSpace In Card Number
        payload.ccNumber = payload.ccNumber.replace(/\s+/g,'');

        let { dataDescriptor, dataValue } = await effects.payments.fetchPaymentTokenCC(payload);

        if(dataDescriptor&&dataValue) {
            state.autopay.token = dataValue;
            state.autopay.tokenDescriptor = dataDescriptor;
        }

        let billingName = extractNameParts(payload.ccName);

        state.autopay.billing.ccFirstName = billingName.firstName;
        state.autopay.billing.ccLastName = billingName.lastName;
        state.autopay.billing.ccEmail = payload.ccEmail;
        state.autopay.billing.ccPhone = payload.ccPhone; 
        state.autopay.billing.ccAddress = payload.ccAddress;
        state.autopay.billing.ccCity = payload.ccCity;
        state.autopay.billing.ccState = payload.ccState;
        state.autopay.billing.ccZip = payload.ccZip;
        state.autopay.billing.ccCode = payload.ccCode;

        return Promise.resolve();

    } catch (errors) {
        state.autopay.tokenErrors = errors;
        return Promise.reject();
    }
}

export const getPaymentTokenACH: AsyncAction<AutoPayFormDataACH> = async ({ state, effects }, payload) => {
    try {

        // Auth.NET Only Allows Up To 22 Characters Without Throwing Error
        if ( payload.achAccountName.length > 20 ) {
            payload.achAccountName = payload.achAccountName.substring(0, 20);
        }

        let { dataDescriptor, dataValue } = await effects.payments.fetchPaymentTokenACH(payload);

        if(dataDescriptor&&dataValue) {
            state.autopay.token = dataValue;
            state.autopay.tokenDescriptor = dataDescriptor;
        }

        let billingName = extractNameParts(payload.achName);

        state.autopay.billing.ccFirstName = billingName.firstName;
        state.autopay.billing.ccLastName = billingName.lastName;
        state.autopay.billing.ccEmail = payload.achEmail;
        state.autopay.billing.ccPhone = payload.achPhone; 
        state.autopay.billing.ccAddress = payload.achAddress;
        state.autopay.billing.ccCity = payload.achCity;
        state.autopay.billing.ccState = payload.achState;
        state.autopay.billing.ccZip = payload.achZip;
        state.autopay.billing.ccCode = 'ACH';

        return Promise.resolve();

    } catch (errors) {
        state.autopay.tokenErrors = errors;
        return Promise.reject();
    }
}

export const acceptTerms: Action<boolean> = ({ state }, status) => {
    state.autopay.termsAccepted = status;
}

export const authorizeAutoPay: Action<boolean> = ({ state }, status) => {
    state.autopay.autoPayAuthorized = status;
}

export const submitAutoPay: AsyncAction = async ({ state, effects }) => {

    window.heap.track('AutoPay_Transaction_Submitted');

    let { token, tokenDescriptor, billing: { ccFirstName, ccLastName, ccEmail, ccPhone, ccAddress, ccCity, ccState, ccZip, ccCode }} = state.autopay;

    if ( 
        token && 
        tokenDescriptor && 
        state.profile.id &&
        ccFirstName &&
        ccLastName &&
        ccEmail &&
        ccPhone &&
        ccAddress &&
        ccCity &&
        ccState &&
        ccZip &&
        ccCode
        ) {

        let payload: AutoPayPayload = {
            code: state.code,
            profile: state.profile.id,
            token,
            tokenDescriptor,
            billing: {
                ccFirstName,
                ccLastName,
                ccEmail,
                ccPhone,
                ccAddress,
                ccCity,
                ccState,
                ccZip,
                ccCode
            }
        }

        try {            
            let success = await effects.autopay.submitAutoPayForm(payload);
            if (success) {
                window.heap.track('AutoPay_Transaction_Success');
                state.autopay.signUpSuccess = true;
                if (state.profile.data) {
                    state.profile.data.autoPayEnabled = true;
                    state.profile.data.autoPayConfigured = true;
                }
            }
            return Promise.resolve();
        } catch (errors) {
            window.heap.track('AutoPay_Transaction_Failure');
            state.autopay.submitErrors = errors;
            return Promise.reject();
        }

    } else {
        window.heap.track('AutoPay_Transaction_Failure');
        state.autopay.submitErrors = [{code:'101', text:'Missing Data'}]
        return Promise.reject()
    } 
 
}

export const next: Action<any> = ({ state }, callback) => {
    if ( typeof callback === 'function' ) {
        callback();
    }
}

export const back: Action<any> = ({ state }, callback) => {
    if ( typeof callback === 'function' ) {
        callback();
    }
}

export const resetErrors: Action = ({state}) => {
    state.autopay.tokenErrors = [];
    state.autopay.submitErrors = [];
}

export const resetState: AsyncAction = async ({ state, actions }) => {
    state.code = null;
    state.autopay.token = null;
    state.autopay.tokenDescriptor = null;
    state.autopay.termsAccepted = false;
    state.autopay.autoPayAuthorized = false;
    state.autopay.tokenErrors = [];
    state.autopay.submitErrors = [];
    state.autopay.billing.ccFirstName = null;
    state.autopay.billing.ccLastName = null;
    state.autopay.billing.ccEmail = null;
    state.autopay.billing.ccPhone = null;
    state.autopay.billing.ccAddress = null;
    state.autopay.billing.ccCity = null;
    state.autopay.billing.ccState = null;
    state.autopay.billing.ccZip = null;
    state.autopay.billing.ccCode = null;
    if(state.profile.id){
        await actions.profile.loadProfile(state.profile.id);
    }
}

export const viewedForm: Action<any> = ({state}, data) => {
    window.heap.track('AutoPay_ViewedFormPage');
}

export const viewedReview: Action<any> = ({state}, data) => {
    window.heap.track('AutoPay_ViewedReviewPage');
}

export const viewedSummary: Action<any> = ({state}, data) => {
    window.heap.track('AutoPay_ViewedSummaryPage');
}

export const checkModeCode: Action = ({state, actions}) => {
    switch (state.code) {
        
        // Check For Update AutoPay Code
        case 'APU1':
            actions.autopay.startUpdateFlow();
            break;

    }
}

export const startUpdateFlow: Action = ({ state }) => {
    window.heap.track('AutoPay_StartedUpdateFlow');
}