import React from 'react';
import { Box, Text, Flex, Grid, Heading } from '@chakra-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import {  getActions, getState } from 'state';

import { PageProps } from 'screens/pages/_types';

import Number from 'components/ui/Number';
import Label from 'components/ui/Label';
import { AnimatedButton, AnimatedHeading, AnimatedText } from 'components/ui/Animated';
import { formatDate } from 'utils/dates';
import ActionBar from 'components/ui/ActionBar';

const Summary: React.FC<PageProps> = ({handleNext}) => {

    const navigate = useNavigate();
    const { profileId } = useParams();
    const { amount, invoiceList,verificationCode } = getState().payments;
    const { resetState } = getActions().payments;

    const [ isSubmitting, setSubmitting ] = React.useState(false);

    const handleReturn= async (action?: 'history' | 'portal' | undefined) => {
        setSubmitting(true);
        try {
            await resetState();
            setSubmitting(false);
            if(handleNext) {
                if (action) {
                    switch (action) {
                        case 'history':
                            handleNext(()=>{
                                navigate(`/history/${profileId}`);
                            });
                            break;
                        case 'portal':
                            handleNext(()=>{
                                navigate(`/portal/${profileId}`);
                            });
                            break;
                        default:
                            handleNext();
                            break;
                    }
                } else {
                    handleNext();
                }
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="Payment Successfull" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Thank you. Your payment has been submitted successfully.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                <Box mb={4}>
                    <Text fontSize="lg" align="center" textStyle="bold">Confirmation Code:</Text>
                    <AnimatedText animate={{scale: [1, 1.5, 1]}} color="green.700" fontSize="3xl" align="center" textStyle="bold">{verificationCode}</AnimatedText>
                </Box>

                <hr/>

                <Box mb={3}>
                    <AnimatedHeading title="" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">The following invoices were successfully paid.</Text>
                    </AnimatedHeading>
                </Box>

                <Box>
                    <Grid templateColumns="1fr 1fr 1fr 1fr" >
                        <Flex align="center" justify="flex-start">
                            <Label>Invoice</Label>
                        </Flex>
                        <Flex align="center" justify="center">
                            <Label>Invoice Date</Label>
                        </Flex>
                        <Flex align="center" justify="center">
                            <Label>Due Date</Label>
                        </Flex>
                        <Flex align="center" justify="flex-end">
                            <Label>Amount</Label>
                        </Flex>
                    </Grid>
                </Box>
                <Box>
                    {invoiceList.map((invoice, key)=>{
                        return (
                            <Box key={key} mb={1}>
                                <Grid templateColumns="1fr 1fr 1fr 1fr" >
                                    <Flex align="center" justify="flex-start">
                                        <Text size="sm">{invoice.full_id}</Text>
                                    </Flex>
                                    <Flex align="center" justify="center">
                                        <Text size="sm">{formatDate(invoice.invoice_date)}</Text>
                                    </Flex>
                                    <Flex align="center" justify="center">
                                        <Text size="sm">{invoice.pastdue_date}</Text>
                                    </Flex>
                                    <Flex align="center" justify="flex-end">
                                        <Heading size="sm">
                                            <Number value={invoice.balance} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                        </Heading>
                                    </Flex>
                                </Grid>
                            </Box>
                        )
                    })}
                </Box>

                <hr/>

                <Box mb={4}>
                    <Text fontSize="lg" align="center" textStyle="bold">Amount Paid: </Text>
                    <AnimatedText animate={{scale: [1, 1.5, 1]}} color="green.700" fontSize="3xl" align="center" textStyle="bold">${amount.toFixed(2)}</AnimatedText>
                </Box>

                <hr/>

                <Text color="gray.500" fontSize="sm" align="center">A confirmation email was sent to the billing contacts on file.</Text>

            </Box>

            <ActionBar>
                <Flex justify="center">
                    <AnimatedButton
                        size="lg"
                        colorScheme="blue"
                        variant="outline"
                        onClick={()=>handleReturn('history')}
                        isLoading={isSubmitting}
                        mr={3}
                    >
                        Review Billing History
                    </AnimatedButton>
                    <AnimatedButton
                        size="lg"
                        colorScheme="blue"
                        onClick={()=>handleReturn()}
                        isLoading={isSubmitting}
                    >
                        Return To Payments
                    </AnimatedButton>
                </Flex>
            </ActionBar>

        </Box>
    );
}

export default Summary;