import Label from 'style/components/Label';
import Badge from 'style/components/Badge';
import Heading from 'style/components/Heading';

export const customTheme = {
    components: {
        Label,
        Badge,
        Heading
    },
    fonts: {
        body: "'Work Sans', sans-serif",
        heading: "'Work Sans', sans-serif"
    },
    breakpoints: {
        sm: '300px',
        md: '700px',
        lg: '1280px',
        xl: '1440px',
    },
    styles: {
        global: {
            body: {
                background: 'linear-gradient(162deg, rgba(251,251,251,1) 0%, rgba(238,238,238,1) 100%)',
                fontWeight: 400,
                minHeight: "100vh",
                paddingBottom: "100px"
            }
        }
    },
    textStyles: {
        center: {
            textAlign: 'center'
        },
        bold: {
            fontWeight: 700
        },
        regular: {
            fontWeight: 400
        },
        draft: {
            textTransform: 'uppercase',
            color: 'blue.200'
        },
        open: {
            textTransform: 'uppercase',
            color: 'blue.500'
        },
        paid: {
            textTransform: 'uppercase',
            color: 'green.500'
        },
        pastdue: {
            textTransform: 'uppercase',
            color: 'orange.500'
        },
        overdue: {
            textTransform: 'uppercase',
            color: 'red.500'
        }
    },
    layerStyles: {
        appBody: {
            background: 'white',
            padding: '20px',
            borderRadius: 8,
            boxShadow: '-1px 5px 18px -8px rgba(0,0,0,0.34)'
        },
        center: {
            textAlign: 'center'
        },
        draft: {
            color: 'blue.200'
        },
        open: {
            color: 'blue.500'
        },
        paid: {
            color: 'green.500'
        },
        pastdue: {
            color: 'orange.500'
        },
        overdue: {
            color: 'red.500'
        },
        selected: {
            borderWidth: 2,
            borderColor: 'blue.500',
            backgroundColor: 'gray.100'
        },
        unselected: {
            borderWidth: 2,
            borderColor: 'gray.100',
            backgroundColor: 'gray.50'
        }
    },
    colors: {
        blue: {
            50: "#dbf8ff",
            100: "#afe4ff",
            200: "#81d0fa",
            300: "#53bef7",
            400: "#25abf3",
            500: "#0c92da",
            600: "#0071aa",
            700: "#00517b",
            800: "#00324d",
            900: "#00121f",
        },
        red: {
            50: '#ffe8de',
            100: '#ffc2b0',
            200: '#ff9b7e',
            300: '#ff744c',
            400: '#ff4d1a',
            500: '#e63400',
            600: '#b42700',
            700: '#811b00',
            800: '#4f0e00',
            900: '#210200',
        },
        orange: {
            50: '#fff2da',
            100: '#ffdbae',
            200: '#ffc57d',
            300: '#ffad4c',
            400: '#ff961a',
            500: '#e67d00',
            600: '#b36100',
            700: '#814500',
            800: '#4f2900',
            900: '#1f0c00',
        },
        yellow: {
            50: '#fff8da',
            100: '#ffeaad',
            200: '#ffdc7d',
            300: '#ffcf4b',
            400: '#ffc11a',
            500: '#e6a700',
            600: '#b38200',
            700: '#805d00',
            800: '#4e3800',
            900: '#1d1300',
        },
        green: {
            50: '#dcffed',
            100: '#adffd4',
            200: '#7cffb9',
            300: '#4afe9e',
            400: '#1bfe84',
            500: '#007f3a',
            600: '#007f3a',
            700: '#007f3a',
            800: '#004d20',
            900: '#001b07',
        }
    },
};