export default {
    variants: {
        draft: {
            textTransform: 'uppercase',
            color: 'blue.200'
        },
        open: {
            textTransform: 'uppercase',
            color: 'blue.500'
        },
        paid: {
            textTransform: 'uppercase',
            color: 'green.500'
        },
        pastdue: {
            textTransform: 'uppercase',
            color: 'orange.500'
        },
        overdue: {
            textTransform: 'uppercase',
            color: 'red.500'
        }
    }
}