import React from 'react';

import { createOvermind } from 'overmind';
import { Provider as StateProvider } from 'overmind-react';

import { config } from 'state';

import Navigation from 'components/Navigation'

function App() {
    const overmind = createOvermind(config,{
        devtools: true
    });
    return (
        <StateProvider value={overmind}>
            <Navigation />
        </StateProvider>
    );
}

export default App;
