import React from 'react';
import { Box, Text, Flex, Checkbox, Center, Button, Alert, Stack, Link, ListItem, OrderedList, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import { useAnimation } from 'framer-motion';

import { PageProps } from 'screens/pages/_types';
import { GenericError } from 'state/_types';

import { AnimatedButton, AnimatedHeading } from 'components/ui/Animated';
import { getActions, getReaction, getState } from 'state';
import ActionBar from 'components/ui/ActionBar';

const POLICY_URL = 'https://www.fastpbx.com/privacy-policy';

const Review: React.FC<PageProps> = ({handleNext, handleBack}) => {

    const { termsAccepted, autoPayAuthorized, submitErrors } = getState().autopay;
    const { paymentMode } = getState().payments;
    const { acceptTerms, authorizeAutoPay, submitAutoPay, resetErrors } = getActions().autopay;
    const reaction = getReaction();
    const controls = useAnimation();
    const [ isSubmitting, setSubmitting ] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    React.useEffect(() => {
        return reaction(
            (state) => state.autopay.termsAccepted && state.autopay.autoPayAuthorized,
            (ready) => {
                if (ready) {
                    controls.start({
                        scale: [1, 1.25, 1]
                    })
                }            
            }
        )
    }, [reaction, controls]);

    const handleTermsAccepted = (status: boolean) => {
        acceptTerms(status);
    }
    
    const handleAutoPayAuthorized = (status: boolean) => {
        authorizeAutoPay(status);
    }

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await submitAutoPay();
            setSubmitting(false);
            if(handleNext) {
                handleNext();
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    const onBack = () => {
        resetErrors();
        if(handleBack) {
            handleBack();
        }
    }

    const displayErrors = () => {
        if (!submitErrors.length) return null;
        return (
            <Stack spacing={3}>
                {submitErrors.map((error: GenericError, key)=>{
                    return (
                        <Alert status="error" variant="left-accent" key={key}>
                            <strong>{error.code}:</strong>{' '}<span>{error.text}</span>
                        </Alert>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Box>

            <Box layerStyle="appBody" mb={5}>

                <Box>
                    <AnimatedHeading title="Review AutoPay Terms" size="md">
                        <Text color="gray.500" fontSize="sm" align="center">Please review our AutoPay terms before continuing.</Text>
                    </AnimatedHeading>
                </Box>

                <hr/>

                <Text>Please review and approve the AutoPay process and terms below.</Text>

                <Box p={4}>
                    <OrderedList>
                        <ListItem>Open invoice reminders will be sent via email for review.</ListItem>
                        <ListItem>Your open invoices will be automatically charged within 72 hours.</ListItem>
                        <ListItem>Payment receipt will be sent via email for review.</ListItem>
                    </OrderedList>
                </Box>

                <Text>By activating Automatic Payments (AutoPay) you authorize FastPBX / Dynamic Packet to automatically charge your account using the payment information provided. Your understand that open invoices will be automatically charged to your payment profile.</Text>

                <hr/>

                <Center>
                    <Flex direction="column">
                        <Checkbox 
                            size="md" 
                            name="acceptAutoPayTerms"
                            isChecked={termsAccepted}
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleTermsAccepted(e.target.checked) }
                            mb={3}
                        > 
                            <Text as="span">{`I have read and accept the `}</Text>
                            <Link color="blue.500" href="#" onClick={onOpen} title="FastPBX AutoPay Terms" rel="noopener noreferrer">FastPBX AutoPay Terms</Link>
                            <Text as="span">{` & `}</Text>
                            <Link color="blue.500" href={POLICY_URL} target="_blank" title="FastPBX Privacy Policy" rel="noopener noreferrer">Privacy Policy</Link>
                        </Checkbox>
                        <Checkbox 
                            size="md" 
                            name="authorizeAutoPay"
                            isChecked={autoPayAuthorized}
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleAutoPayAuthorized(e.target.checked) }
                        > I authorize <strong>FastPBX</strong> to automatically charge the {paymentMode==='cc'?'card':'account'} provided each month.</Checkbox>
                    </Flex>
                </Center>

                <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
                    <ModalOverlay>
                        <ModalContent>
                            <ModalHeader>
                                <Text>AutoPay Terms</Text>
                                <ModalCloseButton />
                            </ModalHeader>
                            <ModalBody>
                                <Text><strong>Terms For Automatic Payments :</strong></Text>
                                <Text>I authorize FastPBX / Dynamic Packet Corporation to charge the credit card or ACH account indicated in the authorization form according to the terms outlined on this page. If the payment dates fall on a weekend or holiday, I understand that the payments may be executed the next business day. I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify FastPBX / Dynamic Packet in writing, of any changes in my account information or termination of this authorization at least 15 days before the next billing date. I certify that I am an authorized user of this account and that I will not dispute charges with my credit card company or banking institution provided that the transaction corresponds to the terms indicated in the sign-up and/or authorization form.</Text>
                            </ModalBody>
                            <ModalFooter></ModalFooter>
                        </ModalContent>
                    </ModalOverlay>
                </Modal>

                <hr/>

                {displayErrors()}

            </Box>

            <ActionBar>
                <Flex justify="space-between">
                    <Button
                        size="lg"
                        variant="outline"
                        colorScheme="blue"
                        onClick={onBack}
                        isDisabled={isSubmitting}
                    >
                        Back
                    </Button>
                    <AnimatedButton
                        size="lg"
                        animate={controls}
                        colorScheme="green"
                        onClick={handleSubmit}
                        isDisabled={termsAccepted && autoPayAuthorized ? false : true}
                        isLoading={isSubmitting}
                    >
                        Complete Sign Up
                    </AnimatedButton>
                </Flex>
            </ActionBar>

        </Box>
    );
}

export default Review;