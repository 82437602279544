import { Action, pipe, mutate, AsyncAction} from 'overmind';
import LogRocket from 'logrocket';

const MaintenanceModeActive = process.env.REACT_APP_MAINTENANCE_MODE === 'true' || false;

type NewNavigationStateOptions = {
    route: string;
    conversation_id?: string;
    callback?(): any;
}

export const updateNavigationState: Action<NewNavigationStateOptions> = pipe(
    mutate(({state}, options) => {
        state.route = options.route;
    }),
    mutate(({state}, options) => {    
        if ( options.callback && typeof options.callback ==='function') {
            options.callback();
        }
    }),
);

// BootStrap The App By Performing Loading Sequence
export const bootstrapApp: AsyncAction = pipe(
    // Set App State To Loading
    mutate(({state})=>{
        state.loading = true;
    }),
    mutate(()=>{
        // Init Log Rocket
        LogRocket.init('55s9e0/fastpbx-payment-portal');
    }),
    mutate(({state})=>{
        // Get URL Route
        let routeArray = window.location.pathname.replace(/^\/+/g, '').split('/');
        // Set Route
        if (routeArray.length) {
            state.route = routeArray[0];
            if (routeArray.length > 1) {
                state.profile.id = routeArray[1]
            }
        }
        // Get URL Params
        let urlParams = new URLSearchParams(window.location.search);
        // Check For CODE Param
        if (urlParams.has('code')) {
            // Set Code
            state.code = urlParams.get('code');
        }
    }),
    mutate(async ({state, actions})=>{
        if (!MaintenanceModeActive && state.profile.id) {
            await actions.profile.loadProfile(state.profile.id);
        }
    }),
    // Set App State To Loaded
    mutate(({state, actions})=>{
        state.loading = false;
    })
);