import AppRoot from 'AppRoot';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from 'screens/Home';
import MaintanancePage from 'screens/Maintenance';
import Migrated from 'screens/Migrated';
import { getState } from 'state';

const MaintenanceModeActive = process.env.REACT_APP_MAINTENANCE_MODE === 'true' || false;

const Navigation = () => {
    const { data } = getState().profile;
    
    if (data&&data.migrated) {

        return (
            <Router>
                <Routes>
                    {MaintenanceModeActive?(
                        <React.Fragment>
                            <Route path="*" element={<MaintanancePage/>} />
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <Route path="*" element={<Migrated/>} />
                        </React.Fragment>
                    )}
                </Routes>
            </Router>
        );

    }
    
    return (
        <Router>
            <Routes>
                {MaintenanceModeActive?(
                    <React.Fragment>
                        <Route path="*" element={<MaintanancePage/>} />
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <Route path="/" element={<Home/>} />
                        <Route path="*" element={<AppRoot/>} />
                    </React.Fragment>
                )}
            </Routes>
        </Router>
    );


}

export default Navigation;