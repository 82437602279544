import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '@chakra-ui/core';

import { getActions, getState } from 'state';

import Invoices from 'screens/pages/payments/Invoices';
import PaymentFormCC from 'screens/pages/payments/PaymentFormCC';
import PaymentFormACH from 'screens/pages/payments/PaymentFormACH';
import Review from 'screens/pages/payments/Review';
import Summary from 'screens/pages/payments/Summary';

type PaymentWizardProps = {
    currentPage: string | number;
    direction?: 'next' | 'back' | null;
    handleNext(callback?: any): void;
    handleBack(callback?: any): void;
}

const PaymentWizard: React.FC<PaymentWizardProps> = ({currentPage, direction, handleNext, handleBack}) => {
    
    const { paymentMode } = getState().payments;
    if (!direction) direction = 'next';

    const variants = {
        next: {
            enter: { x: 300, opacity: 0 },
            target: { x: 0, opacity: 1 },
            exit: { opacity: 0 }
        },
        back: {
            enter: { x: -300, opacity: 0 },
            target: { x: 0, opacity: 1 },
            exit: { opacity: 0 }
        }
    }

    const renderView = () => {
        switch(currentPage) {
            case 'OVERVIEW':
                return <Invoices handleNext={handleNext}/>;
            case 'PAYMENT':
                return paymentMode === 'cc' ? <PaymentFormCC handleNext={handleNext} handleBack={handleBack}/> : <PaymentFormACH handleNext={handleNext} handleBack={handleBack}/>;
            case 'REVIEW':
                return <Review handleNext={handleNext} handleBack={handleBack}/>;
            case 'SUMMARY':
                return <Summary handleNext={handleNext}/>;
            default:
                return null;
        }
    }

    return (
        <Box>
            <motion.div
                variants={variants[direction]}
                initial="enter"
                animate="target"
                exit="exit"
            >
                {renderView()}
            </motion.div>
        </Box>
    )

}

const Payments: React.FC = () => {

    const { current } = getState().payments;
    const { next, back } = getActions().payments;

    const [ direction, setDirection ] = React.useState<'next'|'back'|null>(null);

    const handleNext = (callback?: any) => {
        setDirection('next');
        next(callback);
    }

    const handleBack = (callback?: any) => {
        setDirection('back');
        back(callback);
    }

    return (
        <AnimatePresence exitBeforeEnter>
            <PaymentWizard key={current} currentPage={current} direction={direction} handleNext={handleNext} handleBack={handleBack} />
        </AnimatePresence>
    )

}

export default Payments;