import { Statechart, statechart } from 'overmind-statechart';

import { state } from './state';
import * as actions from './actions';
import * as effects from './effects';

const config = {
    state,
    actions,
    effects
}

const paymentsFlowChart: Statechart<typeof config, {
    OVERVIEW: void
    PAYMENT: void
    REVIEW: void
    SUMMARY: void
}> = {
    initial: 'OVERVIEW',
    states: {
        OVERVIEW: {
            on: {
                selectInvoices: null,
                selectPaymentType: null,
                next: {
                    target: 'PAYMENT',
                    condition: state => !!state.invoices.length
                }
            }
        },
        PAYMENT: {
            entry: 'viewedForm',
            on: {
                back: 'OVERVIEW',
                getPaymentTokenCC: null,
                getPaymentTokenACH: null,
                next: {
                    target: 'REVIEW',
                    condition: state => !!state.token
                }
            }
        },
        REVIEW: {
            entry: 'viewedReview',
            on: {
                back: 'OVERVIEW',
                acceptTerms: null,
                authorizeCharge: null,
                resetErrors: null,
                submitTransactionCC: {
                    target: 'REVIEW',
                    condition: state => state.termsAccepted && state.chargeAuthorized
                },
                submitTransactionACH: {
                    target: 'REVIEW',
                    condition: state => state.termsAccepted && state.chargeAuthorized
                },
                next: {
                    target: 'SUMMARY',
                    condition: state => state.chargeSuccess && !!state.verificationCode
                }
            }
        },
        SUMMARY: {
            entry: 'viewedSummary',
            on: {
                resetState: null,
                next: 'OVERVIEW'
            }
        }
    }
}

export default statechart(config, paymentsFlowChart);