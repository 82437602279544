import { Action, AsyncAction } from 'overmind';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import { generateDeviceId } from 'utils/env';

import InvoiceClass from "./InvoiceClass";
import { SelectInvoiceParams } from "./_types";

declare global {
    interface Window { 
        heap: {
            identify(userId: string): void;
            addUserProperties(data: any): void;
            track(eventId: string, data?: any): void;
        }
    }
}

export const loadProfile: AsyncAction<string> = async ({state, effects}, profileId) => {

    // Identify Session
    let deviceId = generateDeviceId();

    // Get data
    let { invoices, ...profileData } = await effects.profile.getProfile(profileId);

    // Set base data
    state.profile.data = profileData;

    let mappedInvoices = invoices.reduce((map: any, item: any)=>{
        let index = item['invoice_id'];
        return {...map, [index]: new InvoiceClass(item, profileData.collections)};
    },{});

    state.profile.invoiceItems = mappedInvoices;

    Sentry.setUser({
        profile_id: profileId,
        profile_name: profileData.label,
        device_id: deviceId,
        link: `https://profilehub.io/admin/profile/${profileId}`
    });

    LogRocket.identify(deviceId, {
        profile_id: profileId,
        profile_name: profileData.label,
        link: `https://profilehub.io/admin/profile/${profileId}`
    });

    window.heap.identify(deviceId);
    window.heap.addUserProperties({
        profile_id: profileId,
        profile_name: profileData.label,
        link: `https://profilehub.io/admin/profile/${profileId}`
    });

    LogRocket.getSessionURL(sessionURL => {
        window.heap.addUserProperties({
            log_rocket_sesh: sessionURL
        });
        Sentry.configureScope(scope => {
            scope.setExtra("sessionURL", sessionURL);
        });
    });

}

export const selectInvoice: Action<SelectInvoiceParams> = ({state}, params) => {
    state.profile.invoiceItems[params.invoice_id].selected = params.selected;
}