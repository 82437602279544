import React from 'react';
import { motion } from 'framer-motion';
import { Text, Box, Button, Heading } from '@chakra-ui/core';

export const AnimatedButton = motion.custom(Button);
export const AnimatedText = motion.custom(Text);
export const AnimatedHeadingComponent = motion.custom(Box);
export const AnimatedCard = motion.custom(Box);
export const AnimatedBox = motion.custom(Box);

type AnimatedHeadingProps = {
    size?: '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
    title: string;
    subtitle?: string;
}

export const AnimatedHeading: React.FC<AnimatedHeadingProps> = ({size, title, subtitle, children}) => {
    return (
        <AnimatedHeadingComponent initial={{y:-50, opacity: 0}} animate={{y:0, opacity: 1}} >
            <Heading size={size} style={{textAlign: 'center'}}>{title}</Heading>
            {subtitle&&<Text color="gray.500" fontSize="sm">{subtitle}</Text>}
            {children&&(
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </AnimatedHeadingComponent>
    )
}

AnimatedHeading.defaultProps = {
    size: 'lg'
}