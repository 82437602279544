import React from 'react';
import { getActions, getState } from 'state';
import { Badge, Box, Text, Checkbox, Flex, Grid, Heading, Link, Tooltip } from '@chakra-ui/core';

import InvoiceClass from 'state/profile/InvoiceClass';

import { formatDate } from 'utils/dates';

import Number from 'components/ui/Number';
import Label from 'components/ui/Label';
import { generatePDFLink } from 'utils/invoices';

type InvoiceListProps = {
    invoices: InvoiceClass[]
}

const InvoiceList: React.FC<InvoiceListProps> = ({invoices}) => {

    const { id: profile_id } = getState().profile;
    const { selectInvoice } = getActions().profile;

    const handleInvoiceSelected = (selected: boolean, invoice_id: InvoiceClass['invoice_id']) => {
        selectInvoice({ selected, invoice_id });
    }

    return (
        <Box>
            {invoices.length===0?(
                <Box bg="gray.100" p={4} borderRadius={5} mb={2}>
                    <Text color="gray.400" align="center" size="sm">You Have No Upcoming Invoices</Text>
                </Box>
            ):(
                <Text fontSize="sm" align="center" color="gray.500" mb={2}>Tap an invoice to select it</Text>
            )}
            {invoices.map((invoice, key)=>{
                    
                    return (
                        <Box key={key}>

                            <Box display={{sm:'block',md:'none'}} position="relative" layerStyle={invoice.selected?'selected':'unselected'} p={4} borderRadius={5} mb={2} onClick={() => handleInvoiceSelected(!invoice.selected, invoice.invoice_id)}>
                                <Box position="absolute" top="-2px" left="-2px">
                                    <Checkbox 
                                        size="lg"
                                        name="invoiceSelect" 
                                        isChecked={invoice.selected}
                                        bg="white"
                                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => { e.stopPropagation(); handleInvoiceSelected(!e.target.checked, invoice.invoice_id) } }
                                    />
                                </Box>
                                <Grid templateColumns="repeat(2, 1fr)" mb={2}>
                                    <Flex direction="column" align="center" justify="flex-start">
                                        <Label>Invoice</Label>
                                        <Text size="sm">{invoice.full_id}</Text>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="flex-end">
                                        <Label>Balance</Label>
                                        <Text size="sm" layerStyle={invoice.main_status} >
                                            <Number value={invoice.balance} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                            {invoice.partial&&<Tooltip label={`Partial Payment Made: $${invoice.paid}`} aria-label={`Partial Payment Made: $${invoice.paid}`}>*</Tooltip>}
                                        </Text>
                                    </Flex>
                                </Grid>
                                <Grid templateColumns="repeat(2, 1fr)" mb={2}>
                                    <Flex direction="column" align="center" justify="flex-start">
                                        <Label>Invoice Date</Label>
                                        <Text size="sm">{formatDate(invoice.invoice_date)}</Text>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="flex-end">
                                        <Label>Due Date</Label>
                                        <Text size="sm">{invoice.pastdue_date}</Text>
                                    </Flex>
                                </Grid>
                                <Grid templateColumns="repeat(2, 1fr)"> 
                                    <Flex direction="column" align="center" justify="flex-start">
                                        <Label>Status</Label>
                                        <Badge variant={invoice.main_status}>{invoice.main_status}</Badge>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="flex-end">
                                        <Label>Preview</Label>
                                        <Link size="sm" color="blue.500" lineHeight="17px" href={generatePDFLink(invoice.invoice_id, profile_id)} target="_blank" title="View Invoice" rel="noopener noreferrer">View Invoice</Link>
                                    </Flex>
                                </Grid>
                            </Box>

                            <Box display={{sm:'none', md:'block'}} bg="gray.100" p={4} borderRadius={5} mb={2}>
                                <Grid templateColumns="auto 1fr 1fr 1fr auto 1fr auto">
                                    <Flex direction="column" align="center" justify="center">
                                        <Label aria-label="Select Invoice For Payment">Pay Now</Label>
                                        <Checkbox 
                                            size="lg"
                                            name="invoiceSelect" 
                                            isChecked={invoice.selected}
                                            bg="white"
                                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleInvoiceSelected(e.target.checked, invoice.invoice_id) }
                                            />
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Invoice</Label>
                                        <Heading size="sm">{invoice.full_id}</Heading>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Invoice Date</Label>
                                        <Heading size="sm">{formatDate(invoice.invoice_date)}</Heading>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Due Date</Label>
                                        <Heading size="sm">{invoice.pastdue_date}</Heading>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Preview</Label>
                                        <Link size="sm" color="blue.500" lineHeight="17px" href={generatePDFLink(invoice.invoice_id, profile_id)} target="_blank" title="View Invoice" rel="noopener noreferrer">View Invoice</Link>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Status</Label>
                                        <Badge variant={invoice.main_status}>{invoice.main_status}</Badge>
                                    </Flex>
                                    <Flex direction="column" align="center" justify="center">
                                        <Label>Balance</Label>
                                        <Heading size="sm" layerStyle={invoice.main_status} >
                                            <Number value={invoice.balance} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
                                            {invoice.partial&&<Tooltip label={`Partial Payment Made: $${invoice.paid}`} aria-label={`Partial Payment Made: $${invoice.paid}`}>*</Tooltip>}
                                        </Heading>
                                    </Flex>
                                </Grid>
                            </Box>
                            
                        </Box>
                    )

                }
                
            )}
        </Box>
    );
}

export default InvoiceList;