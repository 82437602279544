import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Grid, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Select, Button, InputRightElement, InputGroup, Box, Text, Flex, Stack, Alert } from '@chakra-ui/core';
import InputMask from 'react-input-mask';

import { getActions, getState } from 'state';

import { VerifyError } from 'state/_types';
import { PageProps } from 'screens/pages/_types';
import { AutoPayFormDataACH } from 'state/autopay/_types';

import { AnimatedHeading } from 'components/ui/Animated';

import { IsValid } from 'utils/forms';
import { StatusIcon } from 'components/forms';
import ActionBar from 'components/ui/ActionBar';

const StagingInitialFormData = {
    achAccountName: 'David Melo',
    achAccountNumber: '987654321',
    achAccountNumberVerify: '987654321',
    achRoutingNumber: '067014822',
    achRoutingNumberVerify: '067014822',
    achAccountType: 'checking',
    achName: 'Bobba Fett',
    achEmail: 'bobba@mercs.com',
    achPhone: '7869110690',
    achAddress: '101 Imperial Way',
    achCity: 'Miami',
    achState: 'Fl',
    achZip: '46201'
}

const ProductionInitialFormData = {
    achAccountName: '',
    achAccountNumber: '',
    achAccountNumberVerify: '',
    achRoutingNumber: '',
    achRoutingNumberVerify: '',
    achAccountType: '',
    achName: '',
    achEmail: '',
    achPhone: '',
    achAddress: '',
    achCity: '',
    achState: '',
    achZip: '',
}

const InitialFormData = process.env.REACT_APP_APP_ENV === 'production' ? ProductionInitialFormData : StagingInitialFormData;

const ValidationSchema = yup.object().shape({
    
    achAccountName: yup.string()
        .required('Name on Account Is Required'),

    achAccountNumber: yup.string()
        .required('Account Number Is Required')
        .matches(/^\d+$/, { message: 'Account Number Is Invalid' } ),

    achAccountNumberVerify: yup.string()
        .required('Account Number Is Required')
        .test('achAccountNumberVerify','Account Numbers do not match', function(value) { const ref = yup.ref('achAccountNumber'); return value === this.resolve(ref); } )
        .matches(/^\d+$/, { message: 'Account Number Is Invalid' }) ,

    achRoutingNumber: yup.string()
        .required('Routing Number Is Required')
        .matches(/^\d+$/, { message: 'Routing Number Is Invalid' } )
        .test('length', 'Routing Number Must Be 9 Digits', (value) => value ? value.toString().length === 9 : false ),

    achRoutingNumberVerify: yup.string()
        .required('Verification Routing Number Is Required') 
        .test( 'achRoutingNumberVerify', 'Routing Numbers do not match', function (value) { const ref = yup.ref('achRoutingNumber'); return value === this.resolve(ref); } )
        .matches(/^\d+$/, { message: 'Routing Number Is Invalid' } )
        .test('length', 'Routing Number Must Be 9 Digits', (value) => value ? value.toString().length === 9 : false ),

    achAccountType: yup.string()
        .required('Account Type Is Required'),

    achName: yup.string()
        .required('Name On Card Is Required'),

    achAddress: yup.string()
        .required('Billing Address Is Required'),

    achCity: yup.string()
        .required('Billing City Is Required'),

    achState: yup.string()
        .required('Billing State Is Required'),

    achZip: yup.string()
        .required('Zip Code Is Required')
        .matches(/^\d+$/, { message: 'Zip Code Is Invalid' })
        .test('length', 'Zip Code Must Be 5 Digits', (value) => value ? value.toString().length === 5 : false)
});

const AutoPayFormACH: React.FC<PageProps> = ({handleNext, handleBack}) => {

    const { getPaymentTokenACH } = getActions().autopay;
    const { tokenErrors } = getState().autopay;

    const handleSubmit = async (values: AutoPayFormDataACH, { setSubmitting }: FormikHelpers<AutoPayFormDataACH>) => {
        try {
            await getPaymentTokenACH(values);
            setSubmitting(false);
            if(handleNext) {
                handleNext();
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    const displayErrors = () => {
        if (!tokenErrors.length) return null;
        return (
            <Stack spacing={3}>
                {tokenErrors.map((error: VerifyError, key)=>{
                    return (
                        <Alert status="error" variant="left-accent" key={key}>
                            <strong>{error.code}:</strong>{' '}<span>{error.text}</span>
                        </Alert>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Formik 
            initialValues={InitialFormData}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => { 
            
                return(

                    <form id="requestAutoPayTokenACH" onSubmit={handleSubmit}>

                        <Box layerStyle="appBody" mb={5}>

                            <Box>
                                <AnimatedHeading title="Payment Details" size="md">
                                    <Text color="gray.500" fontSize="sm" align="center">Please provide your payment details below to use for AutoPay.</Text>
                                </AnimatedHeading>
                            </Box>
                            <hr/>

                            <Grid templateRows="1fr" gap={5}>

                                <Grid templateColumns="repeat(2, 1fr)" gap={5}>

                                    <FormControl id="achEmail" isRequired isInvalid={!IsValid('achEmail', touched, errors)}>
                                        <FormLabel>Email Addres</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achEmail"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achEmail}
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achEmail', touched, errors), touched['achEmail'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Contact email address
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achEmail']}</FormErrorMessage>                                
                                    </FormControl>

                                    <FormControl id="achPhone" isRequired isInvalid={!IsValid('achPhone', touched, errors)}>
                                        <FormLabel>Phone Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achPhone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achPhone}
                                                autoComplete="off"
                                                as={InputMask}
                                                mask="(999) 999-9999"
                                                maskChar=" "
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achPhone', touched, errors), touched['achPhone'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Contact phone number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achPhone']}</FormErrorMessage>                                
                                    </FormControl>

                                </Grid>

                                <FormControl id="achName" isRequired isInvalid={!IsValid('achName', touched, errors)}>
                                    <FormLabel>Your Name</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="achName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.achName}
                                            autoComplete="off"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('achName', touched, errors), touched['achName'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Billing contact name
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['achName']}</FormErrorMessage>                                
                                </FormControl>

                                <FormControl id="achAccountName" isRequired isInvalid={!IsValid('achAccountName', touched, errors)}>
                                    <FormLabel>Name On Bank Account</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="achAccountName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.achAccountName}
                                            autoComplete="off"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('achAccountName', touched, errors), touched['achAccountName'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Name on the bank account.
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['achAccountName']}</FormErrorMessage>                                
                                </FormControl>
                    
                                <Grid templateColumns={{sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)'}} gap={6}>
                                
                                    <FormControl id="achAccountNumber" isRequired isInvalid={!IsValid('achAccountNumber', touched, errors)}>
                                        <FormLabel>Account Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achAccountNumber" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achAccountNumber}
                                                data-private="redact"
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achAccountNumber', touched, errors), touched['achAccountNumber'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Bank account number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achAccountNumber']}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="achAccountNumberVerify" isRequired isInvalid={!IsValid('achAccountNumberVerify', touched, errors)}>
                                        <FormLabel>Verify Account Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achAccountNumberVerify"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achAccountNumberVerify}
                                                data-private="redact"
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achAccountNumberVerify', touched, errors), touched['achAccountNumberVerify'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Bank account number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achAccountNumberVerify']}</FormErrorMessage>
                                    </FormControl>

                                </Grid>

                                <Grid templateColumns={{sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)'}} gap={6}>
                                
                                    <FormControl id="achRoutingNumber" isRequired isInvalid={!IsValid('achRoutingNumber', touched, errors)}>
                                        <FormLabel>Routing Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achRoutingNumber" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achRoutingNumber}
                                                data-private="redact"
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achRoutingNumber', touched, errors), touched['achRoutingNumber'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Bank routing number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achRoutingNumber']}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="achRoutingNumberVerify" isRequired isInvalid={!IsValid('achRoutingNumberVerify', touched, errors)}>
                                        <FormLabel>Verify Routing Number</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achRoutingNumberVerify"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achRoutingNumberVerify}
                                                data-private="redact"
                                                autoComplete="off"
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achRoutingNumberVerify', touched, errors), touched['achRoutingNumberVerify'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Bank routing number
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achRoutingNumberVerify']}</FormErrorMessage>
                                    </FormControl>
                                
                                </Grid>
                    
                                <FormControl id="achAccountType"  isRequired isInvalid={!IsValid('achAccountType', touched, errors)}>
                                    <FormLabel>Bank Account Type</FormLabel>
                                    <Select 
                                        placeholder="Select Account Type" 
                                        name="achAccountType" 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.achAccountType}
                                        autoComplete="off"
                                    >
                                        <option value="checking">Checking Account</option>
                                        <option value="businessChecking">Business Checking Account</option>
                                        <option value="savings">Savings Account</option>
                                    </Select>
                                    <FormHelperText>
                                        Bank account type
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['achAccountType']}</FormErrorMessage>
                                </FormControl>

                                <FormControl id="achAddress" isRequired isInvalid={!IsValid('achAddress', touched, errors)}>
                                    <FormLabel>Billing Address</FormLabel>
                                    <InputGroup>
                                        <Input 
                                            type="text" 
                                            name="achAddress"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.achAddress}
                                            autoComplete="off"
                                        />
                                        <InputRightElement children={StatusIcon(IsValid('achAddress', touched, errors), touched['achAddress'])} />
                                    </InputGroup>
                                    <FormHelperText>
                                        Cardholder billing address
                                    </FormHelperText>
                                    <FormErrorMessage>{errors['achAddress']}</FormErrorMessage>                                
                                </FormControl>

                                <Grid templateColumns={{sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap={6}>
                    
                                    <FormControl id="achCity" isRequired isInvalid={!IsValid('achCity', touched, errors)}>
                                        <FormLabel>Billing City</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achCity" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achCity}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achCity', touched, errors), touched['achCity'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing city
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achCity']}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl id="achState" isRequired isInvalid={!IsValid('achState', touched, errors)}>
                                        <FormLabel>Billing State</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achState" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achState}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achState', touched, errors), touched['achState'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing state
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achState']}</FormErrorMessage>
                                    </FormControl>
                    
                                    <FormControl id="achZip" isRequired isInvalid={!IsValid('achZip', touched, errors)}>
                                        <FormLabel htmlFor="achZip">Billing Zip Code</FormLabel>
                                        <InputGroup>
                                            <Input 
                                                type="text" 
                                                name="achZip" 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.achZip}
                                            />
                                            <InputRightElement children={StatusIcon(IsValid('achZip', touched, errors), touched['achZip'])} />
                                        </InputGroup>
                                        <FormHelperText>
                                            Cardholder billing zip dode
                                        </FormHelperText>
                                        <FormErrorMessage>{errors['achZip']}</FormErrorMessage>
                                    </FormControl>
                    
                                </Grid>
                    
                            </Grid>

                            <hr/>

                            {displayErrors()}

                        </Box>

                        <ActionBar>
                            <Flex justify="space-between">
                                <Button
                                    size="lg"
                                    variant="outline"
                                    colorScheme="blue"
                                    isDisabled={isSubmitting}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    size="lg"
                                    colorScheme="blue"
                                    isLoading={isSubmitting}
                                    type="submit"
                                    isDisabled={!isValid}
                                >
                                    Review Payment
                                </Button>
                            </Flex>
                        </ActionBar>

                    </form>

                )
            }}
        </Formik>
    );

}

export default AutoPayFormACH;