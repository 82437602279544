import React from 'react';
import { Alert, AlertDescription, AlertTitle, Flex, Heading, Text, Button } from '@chakra-ui/core';

const SELF_CARE_URL = 'https://account.fastpbx.com/selfcare.do?party=FastPBX'

const Migrated = () => {
    return (
        <Flex direction="column" align="center" justify="center" p={5} height="100vh">
            <img src="https://www.fastpbx.com/assets/img/logo-dark.png" alt="FastPBX Payment Portal" />
            <Heading as="h1" size="md" color="blue.500" my={3}>FastPBX Payment Portal</Heading>
            <Alert width={500} mx="auto" flexDirection="column" alignItems="center">
                <AlertTitle mb={3}>Account Migration Notice</AlertTitle>
                <AlertDescription>
                    <Text align="center">
                        Your payment portal has been migrated to our new and improved billing system.
                        <br />
                        <br />
                        {/* You can access the new portal using the link below. <Button
                            as='a'
                            size="md"
                            colorScheme="blue"
                            href={SELF_CARE_URL}
                        >
                            Access Your New Billing Portal
                        </Button> */}
                        If you have not received an email about your portal migration, please reach out to our support team.
                        <br />
                        <strong>888-988-5355</strong>
                    </Text>
                </AlertDescription>
            </Alert>
        </Flex>
    );
}

export default Migrated;