import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { GetProfileResponse, ProfileHubResponse } from './_types';
import { generateDeviceId } from 'utils/env';

const PROFILE_ENDPOINT_URL = process.env.REACT_APP_PROFILE_ENDPOINT_URL;

export const getProfile = async (profileId: string): Promise<GetProfileResponse> => {

    return new Promise(function(resolve,reject){

        if ( !PROFILE_ENDPOINT_URL ) {
            Sentry.captureMessage('001: No Profile Endpoint Configured');
            return reject([{code: '001', text:'No Profile Endpoint Configured'}]);
        }

        axios.post<ProfileHubResponse<GetProfileResponse>>(PROFILE_ENDPOINT_URL, { id: profileId }, { headers: { 'device-id': generateDeviceId() } })
            .then((res) => {
                if ( res.data.message.success && res.data.message.data ) {
                    return resolve(res.data.message.data);
                } else {
                    return reject(res.data.message);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                return reject(error);
            });

    });

}