import { format, differenceInDays, parseISO, addDays } from 'date-fns';

import { Invoice, ProfileData } from "./_types";

export interface InvoiceInterface {
    id: string;
    invoice_id: string;
    amount: number;
    balance: number;
    status: 'draft' | 'sent' | 'paid' | 'pastdue' | 'overdue';
    created_on: string;
    invoice_date: string;
    notes: string;
    type: string;
    term_days: number;
    grace_days: number;
    selected: boolean;
}

export default class InvoiceClass implements InvoiceInterface {

    full_id: string;
    invoice_id: string;
    amount: number;
    balance: number;
    paid: number = 0;
    status: 'draft' | 'sent' | 'paid' | 'pastdue' | 'overdue';
    created_on: string;
    invoice_date: string;
    notes: string;
    type: string;
    term_days: number;
    grace_days: number;
    selected: boolean = false;
    partial: boolean = false;

    constructor(invoice: Invoice, collections: ProfileData['collections']) {
        this.full_id = invoice.id.split('.').length === 3 ? invoice.id.split('.')[2] : invoice.id;
        this.invoice_id = invoice.invoice_id;
        this.amount = invoice.amount;
        this.balance = invoice.balance;
        this.status = invoice.status;
        this.created_on = invoice.created_on;
        this.invoice_date = invoice.invoice_date;
        this.notes = invoice.notes;
        this.type = invoice.type;
        this.term_days = collections.term_days;
        this.grace_days = collections.grace_days;
        this.initialCalculation();
    }

    initialCalculation = () => {
        if ( this.main_status !== 'paid' ) {
            this.selected = true;
        }
        if ( this.balance > 0 ) {
            if ( this.balance !== this.amount ) {
                this.partial = true;
                this.paid = this.amount - this.balance;
            }
        }
    }

    // Main Status
    get main_status(): 'paid'  | 'pastdue'  | 'overdue'  | 'open' | 'draft' {

        switch (true) {
            
            case this.status === 'draft':
                return 'draft';
                
            case this.status === 'paid':
                return 'paid';

            case this.pastdue_days > 0 && this.overdue_days < 1:
                return 'pastdue';

            case this.overdue_days > 0:
                return 'overdue';

            default:
                return 'open';

        }

    }

    get is_late(): boolean {
        return this.main_status === 'pastdue' || this.main_status === 'overdue';
    }

    // Date Calculations
    get pastdue_on(): Date {
        return addDays(parseISO(this.invoice_date), this.term_days);
    }

    get overdue_on(): Date {
        return addDays(parseISO(this.invoice_date), this.term_days + this.grace_days);
    }

    get pastdue_date(): string {
        return format(this.pastdue_on, 'MM/dd/yyyy');
    }

    get overdue_date(): string {
        return format(this.overdue_on, 'MM/dd/yyyy');
    }

    get aging_days(): number {
        return differenceInDays(new Date(), parseISO(this.invoice_date));
    }

    get pastdue_days(): number {
        return differenceInDays(new Date(), this.pastdue_on);
    }

    get overdue_days(): number {
        return differenceInDays(new Date(), this.overdue_on);
    }

    get is_pastdue(): boolean {
        return this.pastdue_days > 0;
    }

    get is_overdue(): boolean {
        return this.overdue_days > 0;
    }

    // Utils
    get id(): string {
        return this.invoice_id.replace(/\b0+/g, '');
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

}