import { v5 as uuidv5 } from 'uuid';

export const generateDeviceId = (): string => {

    // Navigator Indentifiers
    let browser = navigator.appName;
    let platform = navigator.platform;
    let userAgent = navigator.userAgent;
    let language = navigator.language || '';

    // Web GL Identifiers
    const gl = document.createElement("canvas").getContext("webgl");
    let glVersion = '';
    let glVendor = '';
    let glShader = '';
    let gpuVendor = '';
    let gpuType = '';
    if (gl) {
        glVersion = gl.getParameter(gl.VERSION);
        glVendor = gl.getParameter(gl.VENDOR);
        glShader = gl.getParameter(gl.SHADING_LANGUAGE_VERSION);
        const ext = gl.getExtension("WEBGL_debug_renderer_info");
        if (ext) {
            gpuVendor = gl.getParameter(ext.UNMASKED_VENDOR_WEBGL);
            gpuType = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);
        }
    }
    
    const IdArray = [
        browser,
        platform,
        userAgent,
        language,
        glVersion,
        glVendor,
        glShader,
        gpuVendor,
        gpuType
    ];

    let IdString = IdArray.join(';');

    return uuidv5(btoa(IdString), uuidv5.DNS);
    
}